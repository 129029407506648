import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';

class SettingsForm extends React.Component {
    state = {
        subscription: null,
        error: null,
        saving: false,
        saved: false
    }

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    componentDidMount() {
        API.get('AuthenticatedAPI', `/subscriptions/${this.props.subscriptionId}`).then(subscription => {
            this.setState({ subscription });
        }).catch(error => {
            console.log(error.responses);
        });
    }

    render() {
        console.log(this.state.subscription);

        return (
            <>
                {this.state.saved && (
                    <Alert variant={'light-blue'} className="mb-0 border-blue border-top-0 border-left-0 border-right-0 border-bottom rounded-0">Subscription Updated</Alert>
                )}
                {this.state.cancelled && (
                    <Alert variant={'red'} className="mb-0 border-blue border-top-0 border-left-0 border-right-0 border-bottom rounded-0">Subscription Cancelled</Alert>
                )}

                <div className="min-vh-100 bg-gray">
                    <Container fluid className="py-3">
                        <Row className="justify-content-between">
                            <Col xs={12} sm={10} md={8} lg={6} xl={4}>
                                <fieldset>
                                    <legend>Subscription Details</legend>

                                    {this.state.subscription === null ? (
                                        <div>
                                            <i className="fas fa-spinner fa-fw fa-spin text-blue"></i> Loading
                                        </div>
                                    ) : !this.state.subscription.cancel_at_period_end ? (
                                        <>
                                            <p>
                                                <strong>Next Payment On:</strong> {new Date(1000 * this.state.subscription.current_period_end).toLocaleString()}
                                            </p>

                                            <Button block variant="red" onClick={this.handleButtonClick} disabled={this.state.saving}>Cancel Account</Button>
                                        </>
                                    ) : (
                                        <p className="text-red">
                                            <strong>Account Closes:</strong> {new Date(1000 * this.state.subscription.cancel_at).toLocaleString()}<br/>
                                            Update payment information to reactivate account.
                                        </p>
                                    )}

                                    <hr/>
                                </fieldset>


                                <Form onSubmit={this.handleFormSubmit}>
                                    <fieldset>
                                        <legend>Billing Information</legend>

                                        <Form.Group controlId="card">
                                            <Form.Label>Credit Card</Form.Label>
                                            <CardElement className="form-control form-control-lg pt-3" onChange={this.handleInputChange} />
                                            {this.state.error !== null && (
                                                <Form.Text className="text-red" role="alert">{this.state.error}</Form.Text>
                                            )}
                                        </Form.Group>

                                        <Button type="submit" variant="blue" block disabled={this.state.saving}><i className="far fa-credit-card fa-fw" /> Update Payment Information</Button>
                                    </fieldset>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }

    handleInputChange = (event) => {
        if (event.error) {
            this.setState({ error: event.error.message });
        } else {
            this.setState({ error: null });
        }
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const card = this.props.elements.getElement(CardElement);
        const result = await this.props.stripe.createToken(card);

        this.setState({ error: result.error ? result.error.message : null, saving: true });

        if (result.error) {
            this.setState({ saving: false });
            return;
        }

        try {
            let subscription = await API.put('AuthenticatedAPI', `/subscriptions/${this.props.subscriptionId}`, {
                body: { token: result.token.id }
            });

            this.setState({ subscription, saving: false, saved: true });
        } catch (error) {
            console.log(error.responses);
        }
    };

    handleButtonClick = (event) => {
        this.setState({ saving: true });

        API.del('AuthenticatedAPI', `/subscriptions/${this.props.subscriptionId}`).then(subscription => {
            this.setState({ subscription, saving: false, saved: true });
        }).catch(error => {
            console.log(error.responses);
        });
    };
}

const Settings = (props) => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <SettingsForm elements={elements} stripe={stripe} {...props} />
            )}
        </ElementsConsumer>
    );
};

export default Settings;
