import React from 'react';

class Home extends React.Component {
    render() {
        return (
            <div className="p-3">
                <h1>Dashboard</h1>

                <hr/>

                <p className="lead">TBD: What should go here.</p>
            </div>
        );
    }
}

export default Home;
