import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Loading from './../Loading';
import { API } from 'aws-amplify';

class Coaches extends React.Component {
    state = {
        coaches: null,
        updating: []
    }

    componentDidMount() {
        API.get('AuthenticatedAPI', '/coaches').then(coaches => {
            this.setState({ coaches });
        }).catch(error => {
            console.log(error.responses);
        });
    }

    render() {
        if (this.state.coaches === null) {
            return (
                <Loading className="vh-100" />
            );
        }

        return (
            <div className="p-3">
                <h1>Coaches</h1>

                <Table striped>
                    <thead className="bg-light-blue text-white">
                        <tr>
                            <th>Coach</th>
                            <th>Contact</th>
                            <th>Team</th>
                            <th>Enable/Disable</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.coaches.map(x => {
                            return (
                                <tr key={x.id}>
                                    <td>
                                        {x.name || <span className="text-muted">(no name provided)</span>}
                                        {x.position !== null && (
                                            <div className="small">{x.position}</div>
                                        )}
                                        {x.schoolName !== null && (
                                            <div className="small">{x.schoolName}</div>
                                        )}
                                    </td>
                                    <td>
                                        <a href={`mailto:${x.email}`} className="small"><i className="fa fa-envelope fa-fw"></i> {x.email}</a><br/>
                                        {x.phone !== null && (
                                            <a href={`tel:${x.phone}`} className="small"><i className="fa fa-phone fa-fw"></i> {x.phone}</a>
                                        )}
                                    </td>
                                    <td className="small">
                                        <strong>Team Name:</strong> {x.teamName}<br/>
                                        <strong>Team Address:</strong> {x.teamAddress}<br/>
                                        <strong>Division:</strong> {x.division}
                                    </td>
                                    <td>
                                        <Button variant={x.enabled ? 'red' : 'blue'} onClick={() => this.toggleEnable(x)} disabled={this.state.updating.includes(x.id)}>{x.enabled ? 'Disable' : 'Enable'}</Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }

    toggleEnable = async (coach) => {
        this.setState({ updating: [ ...this.state.updating, coach.id ] });

        try {
            await API.put('AuthenticatedAPI', `/coaches/${coach.id}`, {
                body: {
                    ...coach,
                    enabled: !coach.enabled
                }
            });

            this.setState({
                coaches: this.state.coaches.map(x => x.id === coach.id ? { ...x, enabled: !x.enabled } : x),
                updating: this.state.updating.filter(x => x !== coach.id)
            });
        } catch (error) {
            this.setState({
                coaches: this.state.coaches.map(x => x.id === coach.id ? { ...x, enabled: !x.enabled } : x),
                updating: this.state.updating.filter(x => x !== coach.id)
            });
            console.log(error);
            console.log(error.responses);
            return;
        }
    }
}

export default Coaches;
