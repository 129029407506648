import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { API } from 'aws-amplify';
import CollegeLocations from './../Athlete/CollegeLocations';

class Home extends React.Component {
    state = {
        athletes: null,
        searching: false
    }

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        if (!this.props.coach.enabled) {
            return (
                <>
                    <h1 className="display-1">Thank you for registering!</h1>
                    <hr/>
                    <p className="lead">Your account is pending review. To expedite the review process, please <Link to="/coach/profile" href="/coach/profile" className="text-light-blue font-weight-medium">answer a few questions</Link>.</p>
                </>
            );
        }

        return (
            <>
                <div className="bg-white p-3">
                    <h3>Search Athletes</h3>

                    <Form onSubmit={this.handleFormSubmit}>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group controlId="graduationYear">
                                    <Form.Label>Graduation Year</Form.Label>
                                    <Form.Control size="sm" type="number" name="graduationYear" onChange={this.handleInputChange} />
                                </Form.Group>

                                <Form.Group controlId="disciplines">
                                    <Form.Label>Shooting Discipline</Form.Label>
                                    <Form.Control size="sm" as="select" name="shootingDiscipline" custom onChange={this.handleInputChange}>
                                        <option value="">Any</option>
                                        <option value="shotgunSuper">Shotgun: Super Sporting</option>
                                        <option value="shotgunClays">Shotgun: Sporting Clays</option>
                                        <option value="shotgunAmericanSkeet">Shotgun American: Skeet</option>
                                        <option value="shotgunAmericanTrap">Shotgun American: Trap</option>
                                        <option value="shotgunInternationalSkeet">Shotgun International: Skeet</option>
                                        <option value="shotgunInternationalTrap">Shotgun International: Trap</option>
                                        <option value="rifle">Rifle</option>
                                        <option value="pistol">Pistol</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="location">
                                    <Form.Label>Desired College Location</Form.Label>
                                    <Form.Control size="sm" as="select" name="collegeLocation" custom onChange={this.handleInputChange}>
                                        <option value="">Any</option>
                                        {Object.keys(CollegeLocations).map(x => {
                                            return <option key={x} value={x}>{CollegeLocations[x]}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={6} md={4}>
                                <Row>
                                    <Col xs={4} sm={12}>
                                        <Form.Group controlId="gpa">
                                            <Form.Label>Min GPA</Form.Label>
                                            <Form.Control size="sm" type="number" name="gpa" step=".001" onChange={this.handleInputChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={4} sm={12}>
                                        <Form.Group controlId="sat">
                                            <Form.Label>Min SAT</Form.Label>
                                            <Form.Control size="sm" type="number" name="sat" onChange={this.handleInputChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={4} sm={12}>
                                        <Form.Group controlId="act">
                                            <Form.Label>Min ACT</Form.Label>
                                            <Form.Control size="sm" type="number" name="act" step="1" onChange={this.handleInputChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>Competition Outcomes</Form.Label>
                                    <Row>
                                        <Col xs={6} md={12}>
                                            <Form.Check type="checkbox" id="stateWin" name="stateWin" label="State Win" onChange={this.handleInputChange} />
                                        </Col>

                                        <Col xs={6} md={12}>
                                            <Form.Check type="checkbox" id="nationalWin" name="nationalWin" label="National Win" onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Button type="submit" variant="blue" disabled={this.state.searching}>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {this.state.searching ? (
                    <div className="d-flex justify-content-center align-items-center py-5">
                        <i className="fas fa-spinner fa-fw fa-spin fa-5x text-blue"></i>
                        <h1 className="display-3 mb-0">Loading...</h1>
                    </div>
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Athlete Snapshot</th>
                                <th>Schooling &amp; Testing</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.athletes && this.state.athletes.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <strong>Name:</strong> <Link to={`/coach/athletes/${x.id}`}>{x.name}</Link><br/>
                                            <strong>Class Of:</strong> {x.graduationYear}<br/>
                                            <strong>Home State:</strong> {x.state}
                                        </td>
                                        <td>
                                            <strong>GPA:</strong> {x.gpa}<br/>
                                            <strong>SAT:</strong> {x.sat}<br/>
                                            <strong>ACT:</strong> {x.act}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )}
            </>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const type = target.type;
        const name = target.name;
        let value;

        switch (type) {
            case 'checkbox':
                value = target.checked;
                break;
            default:
                value = target.value;
                break;
        }

        this.setState({ [name] : value });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ searching: true });

        try {
            let queryStringParameters = {};

            if (this.state.graduationYear) {
                queryStringParameters.graduationYear = this.state.graduationYear;
            }

            if (this.state.shootingDiscipline) {
                queryStringParameters.shootingDiscipline = this.state.shootingDiscipline;
            }

            if (this.state.collegeLocation) {
                queryStringParameters.collegeLocation = this.state.collegeLocation;
            }

            if (this.state.gpa) {
                queryStringParameters.gpa = this.state.gpa;
            }

            if (this.state.sat) {
                queryStringParameters.sat = this.state.sat;
            }

            if (this.state.act) {
                queryStringParameters.act = this.state.act;
            }

            if (this.state.stateWin) {
                queryStringParameters.stateWin = this.state.stateWin;
            }

            if (this.state.nationalWin) {
                queryStringParameters.nationalWin = this.state.nationalWin;
            }

            let athletes = await API.get('AuthenticatedAPI', `/athletes`, { queryStringParameters });

            this.setState({ searching: false, athletes });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            console.log(error);
            console.log(error.responses);
            return;
        }
    }
}

export default Home;
