import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';

const PRICE = 59;

class SubscribeForm extends React.Component {
    state = {
        error: null,
        submitting: false,
        coupon: '',
        couponError: null,
        discount: 0
    }

    constructor(props) {
        super(props);

        this.handleCardChange = this.handleCardChange.bind(this);
        this.handleCouponChange = this.handleCouponChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        return (
            <div className="min-vh-100 bg-gray">
                <Container className="py-5">
                    <h1 className="display-4 text-center">Just one more step...</h1>
                    <p className="lead text-center mb-0">Provide your payment information and get started immediately!</p>

                    <hr/>

                    <Row className="justify-content-center mt-4">
                        <Col xs={12} sm={10} md={8} lg={6} xl={4}>
                            <Form onSubmit={this.handleFormSubmit}>
                                {PRICE - this.state.discount > 0 && (
                                    <Form.Group controlId="card">
                                        <Form.Label>Credit Card</Form.Label>
                                        <CardElement className="form-control form-control-lg pt-3" onChange={this.handleCardChange} />
                                        {this.state.error !== null && (
                                            <Form.Text className="text-red" role="alert">{this.state.error}</Form.Text>
                                        )}
                                    </Form.Group>
                                )}

                                <Form.Group controlId="coupon">
                                    <Form.Label>Promotional Code</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" value={this.state.coupon} onChange={this.handleCouponChange} />
                                        <InputGroup.Append>
                                            <Button variant="red" onClick={this.handleCoupon} disabled={this.state.submitting}>Apply</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {this.state.couponError !== null && (
                                        <Form.Text className="text-red">{this.state.couponError}</Form.Text>
                                    )}
                                    {this.state.discount > 0 && (
                                        <Form.Text className="text-blue font-weight-bold">You saved ${this.state.discount}</Form.Text>
                                    )}
                                </Form.Group>

                                <Button type="submit" size="lg" block disabled={this.state.submitting}>Get started for ${PRICE - this.state.discount}/year</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    handleCardChange = (event) => {
        if (event.error) {
            this.setState({ error: event.error.message });
        } else {
            this.setState({ error: null });
        }
    }

    handleCouponChange = (event) => {
        this.setState({ coupon: event.target.value, couponError: null });
    }

    handleCoupon = async (event) => {
        try {
            let coupon = await API.get('AuthenticatedAPI', `/coupons/${this.state.coupon}`);

            if (coupon.percent_off) {
                this.setState({ discount: (PRICE * coupon.percent_off / 100).toFixed(2) });
            } else {
                this.setState({ discount: coupon.amount_off / 100 });
            }
        } catch (error) {
            switch (error.response.status) {
                case 404:
                    this.setState({ couponError: 'Invalid promotional code.' });
                    break;
                default:
                    console.log(error.response);
            }
        }
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();

        let token = null;
        let coupon = this.state.coupon;

        this.setState({ error: null, submitting: true });

        if (PRICE - this.state.discount > 0) {
            const card = this.props.elements.getElement(CardElement);
            const result = await this.props.stripe.createToken(card);

            if (result.error) {
                this.setState({ error: result.error.message, submitting: false });
                return;
            }

            token = result.token.id;
        }

        try {
            await API.post('AuthenticatedAPI', `/athletes/${this.props.athleteId}/subscriptions`, {
                body: { token, coupon }
            });

            window.location.reload();
        } catch (error) {
            console.log(error.responses);
        }
    };
}

const Subscribe = (props) => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <SubscribeForm elements={elements} stripe={stripe} {...props} />
            )}
        </ElementsConsumer>
    );
};

export default Subscribe;
