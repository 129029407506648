import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from '../Loading';
import Navigation from './Navigation';
import Home from './Home';
import Athlete from './Athlete';
import Profile from './Profile';
import { Auth, API } from 'aws-amplify';

class App extends React.Component {
    state = {
        coach: null,
        redirectToLogin: false
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(user => {
            if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'coach') {
                this.setState({ redirectToLogin: true });
            } else {
                this.getCoach(user.username);
            }
        }).catch(error => {
            this.setState({ redirectToLogin: true });
        });
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login' }} />
        }

        if (!this.state.coach) {
            return (
                <Loading className="vh-100 bg-gray" />
            );
        }

        return (
            <div className="min-vh-100 bg-gray">
                <Navigation />

                <Switch>
                    <Route exact path="/coach">
                        <Home coach={this.state.coach} />
                    </Route>
                    <Route path="/coach/athletes/:id" component={Athlete} />
                    <Route exact path="/coach/profile">
                        <Profile coach={this.state.coach} />
                    </Route>
                </Switch>
            </div>
        );
    }

    getCoach = async (userId) => {
        let coach;

        try {
            coach = await API.get('AuthenticatedAPI', `/coaches/${userId}`);
        } catch (error) {
            console.log(error.responses);
        }

        this.setState({ coach });
    }
}

export default App;
