import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { Auth, Storage } from 'aws-amplify';

class General extends React.Component {
    state = {
        uploading: null,
        headshotFilePath: null
    }

    _initialBirthday;

    constructor(props) {
        super(props);

        if (props.athlete.birthday) {
            let birthdayDate = new Date(props.athlete.birthday);

            this._initialBirthday = [
                birthdayDate.getUTCFullYear(),
                (birthdayDate.getUTCMonth() + 1).toString().padStart(2, '0'),
                birthdayDate.getUTCDate().toString().padStart(2, '0')
            ].join('-');
        }

        if (props.athlete.headshot) {
            this.loadHeadshot();
        }
    }

    render() {
        return (
            <>
                <fieldset>
                    <legend>Introduction</legend>

                    <Row>
                        <Col xs={12} sm={5} md={4} lg={3}>
                            <Form.Group controlId="name">
                                <Form.Label><i className="fas fa-id-badge fa-fw"></i> Name</Form.Label>
                                <Form.Control type="text" name="name" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.name} />
                            </Form.Group>

                            <Form.Group controlId="headshot">
                                <Form.Label><i className="fas fa-smile fa-fw"></i> Headshot</Form.Label>
                                {this.state.headshotFilePath !== null && (
                                    <Image fluid thumbnail src={this.state.headshotFilePath} />
                                )}
                                <Form.File custom name="headshot" label={this.props.athlete.headshot || "Choose a file"} size={"sm"} onChange={this.handleHeadshotChange} accept="image/png, image/jpeg, image/gif" />
                                {this.state.uploading !== null && (
                                    <Form.Text className="text-muted">Upload Progress: {this.state.uploading}%</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Check type="checkbox" id="published" name="published" label="Make profile visible to coaches" onChange={this.props.inputChange} defaultChecked={this.props.athlete.published} />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId="summary">
                                <Form.Label><i className="fas fa-comment-alt fa-fw"></i> Summary</Form.Label>
                                <Form.Control as="textarea" name="summary" rows="15" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.summary} />
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>

                <hr/>

                <Row>
                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Contact Info</legend>

                            <Form.Group controlId="name">
                                <Form.Label><i className="fas fa-id-badge fa-fw"></i> Email (username)</Form.Label>
                                <Form.Control type="email" name="email" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.email} />
                                <Form.Text className="text-muted">Changing this will also change your login credentials.</Form.Text>
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label><i className="fas fa-phone fa-fw"></i> Phone #</Form.Label>
                                <Form.Control type="tel" name="phone" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.phone} />
                            </Form.Group>

                            <Form.Group controlId="address">
                                <Form.Label><i className="fas fa-road fa-fw"></i> Address</Form.Label>
                                <Form.Control type="text" name="address" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.address} />
                            </Form.Group>

                            <Form.Group controlId="city">
                                <Form.Label><i className="fas fa-city fa-fw"></i> City</Form.Label>
                                <Form.Control type="text" name="city" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.city} />
                            </Form.Group>

                             <Form.Group controlId="state">
                                <Form.Label><i className="fas fa-flag-usa fa-fw"></i> State</Form.Label>
                                <Form.Control type="text" name="state" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.state} />
                            </Form.Group>
                        </fieldset>
                    </Col>

                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Demographics</legend>

                            <Form.Group controlId="birthday">
                                <Form.Label><i className="fas fa-calendar-day fa-fw"></i> Birthday</Form.Label>
                                <Form.Control type="date" name="birthday" size={"sm"} onChange={this.props.inputChange} defaultValue={this._initialBirthday || ""} />
                            </Form.Group>

                            <Form.Group controlId="height">
                                <Form.Label><i className="fas fa-ruler-vertical fa-fw"></i> Height (inches)</Form.Label>
                                <Form.Control type="number" name="height" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.height} />
                            </Form.Group>

                            <Form.Group controlId="weight">
                                <Form.Label><i className="fas fa-weight fa-fw"></i> Weight (pounds)</Form.Label>
                                <Form.Control type="number" name="weight" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.weight} />
                            </Form.Group>

                            <Form.Group controlId="ethnicity">
                                <Form.Label><i className="fas fa-globe-americas fa-fw"></i> Ethnicity</Form.Label>
                                <Form.Control type="text" name="ethnicity" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.ethnicity} />
                            </Form.Group>

                            <Form.Group controlId="religion">
                                <Form.Label><i className="fas fa-pray fa-fw"></i> Religion</Form.Label>
                                <Form.Control type="text" name="religion" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.religion} />
                            </Form.Group>
                        </fieldset>
                    </Col>

                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Social Media</legend>

                            <Form.Group controlId="twitter">
                                <Form.Label><i className="fab fa-twitter fa-fw"></i> Twitter</Form.Label>
                                <Form.Control type="url" name="twitter" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.twitter} />
                            </Form.Group>

                            <Form.Group controlId="facebook">
                                <Form.Label><i className="fab fa-facebook fa-fw"></i> Facebook</Form.Label>
                                <Form.Control type="url" name="facebook" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.facebook} />
                            </Form.Group>

                            <Form.Group controlId="snapchat">
                                <Form.Label><i className="fab fa-snapchat fa-fw"></i> Snapchat</Form.Label>
                                <Form.Control type="url" name="snapchat" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.snapchat} />
                            </Form.Group>

                            <Form.Group controlId="instagram">
                                <Form.Label><i className="fab fa-instagram fa-fw"></i> Instagram</Form.Label>
                                <Form.Control type="url" name="instagram" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.instagram} />
                            </Form.Group>

                            <Form.Group controlId="youtube">
                                <Form.Label><i className="fab fa-youtube fa-fw"></i> Youtube</Form.Label>
                                <Form.Control type="url" name="youtube" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.youtube} />
                            </Form.Group>
                        </fieldset>
                    </Col>
                </Row>
            </>
        );
    }

    handleHeadshotChange = (event) => {
        event.persist();

        if (event.target.files.length > 0) {
            this.setState({ uploading: 0 });

            const file = event.target.files[0];
            let that = this;

            Storage.put(file.name, file, {
                level: 'protected',
                contentType: file.type,
                progressCallback(progress) {
                    that.setState({ uploading: Math.round(100 * progress.loaded/progress.total) });
                }
            })
            .then(result => {
                this.setState({ uploading: null });
                this.props.inputChange(event);
                this.loadHeadshot();
            })
            .catch(err => console.log(err));
        } else {
            this.props.inputChange(event);
        }
    }

    loadHeadshot = () => {
        Auth.currentUserInfo().then(user => {
            Storage.get(this.props.athlete.headshot, {
                level: 'protected',
                identityId: user.id
            })
            .then(result => {
                this.setState({ headshotFilePath: result });
            })
            .catch(err => console.log(err));
        });
    }
}

export default General;
