import React from 'react';
import { withRouter } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Auth } from 'aws-amplify';

class Navigation extends React.Component {
    state = {
        redirectToLogin: false
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login' }} />
        }

        return (
            <Navbar variant="dark" expand="sm" className="flex-sm-column align-items-start">
                <Navbar.Brand as={Link} to="/" href="/"><Image src="/images/logo-xs.png" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="primary-navigation" />

                <Navbar.Collapse id="primary-navigation">
                    <Nav activeKey={this.props.location.pathname} className="flex-column">
                        <Nav.Link as={Link} to="/admin" href="/admin">Dashboard</Nav.Link>
                        <Nav.Link as={Link} to="/admin/athletes" href="/admin/athletes">Athletes</Nav.Link>
                        <Nav.Link as={Link} to="/admin/coaches" href="/admin/coaches">Coaches</Nav.Link>
                        <Nav.Link onClick={this.logout} className="text-light-blue">Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

    logout = async () => {
        try {
            await Auth.signOut();

            this.setState({ redirectToLogin: true });
        } catch (error) {
            console.log('error signing out:', error);
        }
    }
}

export default withRouter(Navigation);
