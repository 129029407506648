import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth, API } from 'aws-amplify';

class Register extends React.Component {
    state = {
        group: 'athlete',
        email: '',
        password: '',
        submitting: false,
        error: null,
        redirectToVerification: false
    }

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        if (this.state.redirectToVerification) {
            return <Redirect to={{ pathname: '/verify', state: { email: this.state.email } }} />
        }

        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Sign Up</h1>
                    <p className="lead">Welcome to College Shooting Sports Recruiting sign up. We are a platform to help you in your search for a great fit in academics and your goal to compete in Shooting Sports at the college level.</p>

                    <hr/>

                    <Row className="justify-content-center">
                        <Col sm={10} md={8} lg={6} xl={4} className="bg-white rounded py-3">
                            <h3>Create an Account</h3>

                            <Form onSubmit={this.handleFormSubmit}>
                                <Form.Group controlId="group">
                                    <Form.Label>I am a{this.state.group === 'athlete' && 'n'}:</Form.Label><br/>
                                    <Form.Check type="radio" inline name="group" label="Athlete" id="group-athlete" checked={this.state.group === 'athlete'} onChange={this.handleInputChange} value="athlete" />
                                    <Form.Check type="radio" inline name="group" label="Coach" id="group-coach" checked={this.state.group !== 'athlete'} onChange={this.handleInputChange} value="coach" />
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="email@address.com" required onChange={this.handleInputChange} value={this.state.email} />
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$" onChange={this.handleInputChange} value={this.state.password} />
                                    <Form.Text className="text-muted">Must be at least 8 character long, contain one uppercase letter, one lowercase letter, and one number.</Form.Text>
                                </Form.Group>

                                {this.state.error && (
                                    <Alert variant="red">{this.state.error}</Alert>
                                )}

                                <Button size={"lg"} variant="blue" type="submit" block disabled={this.state.submitting}>Sign Up</Button>
                            </Form>
                        </Col>
                    </Row>

                    <hr/>

                    <p>If you have already created an account, please <Link to="/login" href="/login">use your username and password</Link> to go to your profile.</p>
                </Container>
            </div>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        let user;

        this.setState({ submitting: true });

        try {
            user = await Auth.signUp({
                username: this.state.email,
                password: this.state.password,
                attributes: { email: this.state.email }
            });
        } catch (error) {
            switch (error.code) {
                case 'UsernameExistsException':
                    this.setState({ error: 'An account already exists for this account. Try signing in instead.', submitting: false });
                    break;
                default:
                    console.log('error signing up:', error);
                    break;
            }
        }

        // Call API to create athlete or coach.
        try {
            switch (this.state.group) {
                case 'athlete':
                    await API.post('UnauthenticatedAPI', '/athletes', {
                        body: {
                            id: user.userSub,
                            email: this.state.email
                        }
                    });
                    break;
                case 'coach':
                    await API.post('UnauthenticatedAPI', '/coaches', {
                        body: {
                            id: user.userSub,
                            email: this.state.email
                        }
                    });
                    break;
                default:
                    throw new Error('Unsupported group');
            }
        } catch (error) {
            console.log(error.response);
            return;
        }

        this.setState({ error: false, redirectToVerification: true });
    }
}

export default Register;
