import React from 'react';
import Container from 'react-bootstrap/Container';

class Financial extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Financial Aid</h1>

                    <hr/>

                    <p>Almost everyone who applies for financial aid receives some kind of help. There are billions of dollars reserved each year for grants, scholarships and financial aid for college students. You just have to apply. Here are some fun facts about financial aid:</p>
           
                    <ul>
                        <li>Financial aid is just that... aid. It is not intended to pay for all of your college. Your EFC is your “expected family contribution.” Your EFC isdetermined by FAFSA and will likely be an indicator of how much financial aid you might receive.</li>
                        <li>Financial aid is not intended to pay for iphones, cars, travel or credit card debt. It is meant to assist with tuition, room & board, books, etc.</li>
                        <li>Complete the FAFSA form as close to October 1st (of your senior year) as possible. These funds to go quickly and if you qualify, it is first come, first serve. FAFSA can be overwhelming, we recommend completing it with your parents and having a school counselor’s contact info nearby.</li>
                        <li>Need-based financial aid comes in three forms: grants, work-study and loans. Grants are like scholarships, but rather than being merit based, they are need based. Work-study aid is when a student works on campus or does community service. Loans are money that you borrow to pay for college and then pay back. This is very common and although it doesn’t seem like “free” money, there are many different types of loans with all sorts of repayment options.</li>
                    </ul>

                    <h2>FAFSA</h2>
                    <p><a href="https://www.fafsa.ed.gov" target="_blank" rel="noopener noreferrer">www.fafsa.ed.gov</a></p>
                    <p>What you need to know about FAFSA:</p>
            
                    <ul>
                        <li>The US Department of Education awards about $150 billion dollars each year in financial aid to help students pay for their education.</li>
                        <li>Almost all students who apply, qualify for some sort of aid. It could be in the form of grants, work-study or loans.</li>
                        <li>You will first need a FAFSA ID. This will serve as your electronic signature and you will need it each time you login. To receive an ID, you’ll need your name, SSN and birthdate.</li>
                        <li>You can start applying October 1st of your senior year. The federal cutoff is June 30th, although some schools have earlier deadlines.</li>
                        <li>FAFSA will ask information regarding income, assets andd emographic factors. You will need tax information from the prior-prior year, investment information, bank statements, untaxed income, DL number and a list of schools you are interested in attending.</li>
                    </ul>

                    <h2>529 College Savings</h2>
                    <p><a href="https://www.collegesavings.org" target="_blank" rel="noopener noreferrer">www.collegesavings.org</a></p>

                    <p>What you need to know about state college savings plans.</p>

                    <h2>Scholarship Listings / Links</h2>
                    <p><a href="https://www.scholarships.com" target="_blank" rel="noopener noreferrer">www.scholarships.com</a></p>
                    <p><a href="https://www.myscholly.com" target="_blank" rel="noopener noreferrer">www.myscholly.com</a></p>
                    <p><a href="http://www.gocollege.com/" target="_blank" rel="noopener noreferrer">www.gocollege.com</a></p>
                    <p><a href="https://awards.nra.org/scholarships/" target="_blank" rel="noopener noreferrer">awards.nra.org/scholarships/</a></p>
                </Container>
            </div>
        );
    }
}

export default Financial;
