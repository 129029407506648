import React from 'react';
import Container from 'react-bootstrap/Container';

class Cancellation extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Subscription Cancellation Policy</h1>
                    <p>Once subscription account is set up, the Purchaser will automatically be billed either annually or monthly based upon purchaser’s preference during account sign up. The Purchaser may cancel the subscription at any time. If Monthly subscription, the Purchaser is responsible for the full subscription fee in the monthly billing cycle in which the Purchaser cancels. Once the Purchaser’s account has been billed, all sales are final and there will be no refunds regardless of annual or monthly subscription account payment cycles.</p>
 
                    <h2>How to Cancel</h2>
                    <p>To cancel your subscription, please go to the Purchaser’s billing portal and click on the “Cancel” link. You will receive an email to confirm the cancellation. Be sure to reply to the cancellation email with “Cancel Subscription”, Be Sure to place the account profile name and unique account number in the reply email, the account and subscription will be canceled within five (5) business day from receipt of the cancellation email.</p>
                </Container>
            </div>
        );
    }
}

export default Cancellation;
