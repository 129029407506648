import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import General from './Profile/General';
import School from './Profile/School';
import College from './Profile/College';
import Guardians from './Profile/Guardians';
import Endorsements from './Profile/Endorsements';
import Tournaments from './Profile/Tournaments';
import Media from './Profile/Media';
import { API } from 'aws-amplify';

class Profile extends React.Component {
    state = {
        athlete: null,
        tab: 'general',
        saving: false,
        saved: false,
        error: false
    }

    constructor(props) {
        super(props);

        this.state.athlete = props.athlete;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        return (
            <>
                {this.state.saved && (
                    <Alert variant={'light-blue'} className="mb-0 border-blue border-top-0 border-left-0 border-right-0 border-bottom rounded-0">Profile Updated</Alert>
                )}
                {this.state.error && (
                    <Alert variant={'red'} className="mb-0 border-blue border-top-0 border-left-0 border-right-0 border-bottom rounded-0">{this.state.error}</Alert>
                )}


                <div className="bg-white overflow-scroll p-1 border-bottom border-blue">
                    <Nav variant="pills" defaultActiveKey={this.state.tab} onSelect={this.handleNavSelect} className="justify-content-around">
                        <Nav.Item>
                            <Nav.Link eventKey="general"><i className="fas fa-user fa-fw"></i> General</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="schooling"><i className="fas fa-school fa-fw"></i> School &amp; Experience</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="college"><i className="fas fa-university fa-fw"></i> College Goals</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="guardians"><i className="fas fa-user-shield fa-fw"></i> Guardians</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="endorsements"><i className="fas fa-award fa-fw"></i>Endorsements</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tournaments"><i className="fas fa-calendar-alt fa-fw"></i> Upcoming Tournaments</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="media"><i className="fas fa-photo-video fa-fw"></i> Media</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <Container fluid className="py-2">
                    <Form onSubmit={this.handleFormSubmit}>
                        {
                            {
                                general: <General athlete={this.state.athlete} inputChange={this.handleInputChange} />,
                                schooling: <School athlete={this.state.athlete} inputChange={this.handleInputChange} />,
                                college: <College athlete={this.state.athlete} inputChange={this.handleInputChange} />,
                                guardians: <Guardians athlete={this.state.athlete} inputChange={this.handleInputChange} addGuardian={this.handleAddGuardian} removeGuardian={this.handleRemoveGuardian} />,
                                endorsements: <Endorsements athlete={this.state.athlete} inputChange={this.handleInputChange} addEndorsement={this.handleAddEndorsement} removeEndorsement={this.handleRemoveEndorsement} />,
                                tournaments: <Tournaments athlete={this.state.athlete} inputChange={this.handleInputChange} addTournament={this.handleAddTournament} removeTournament={this.handleRemoveTournament} />,
                                media: <Media athlete={this.state.athlete} inputChange={this.handleInputChange} removeFile={this.handleRemoveFile} />
                            }[this.state.tab]
                        }

                        <Button variant="blue" type="submit" disabled={this.state.saving}>Update Profile</Button>
                    </Form>
                </Container>
            </>
        );
    }

    handleNavSelect = (tab) => {
        this.setState({ tab });
    }

    handleInputChange = (event) => {
        // Find property and value to update athlete with.
        const target = event.target;
        const type = target.type;
        const name = target.name;
        let value;

        switch (type) {
            case 'file':
                if (target.multiple) {
                    value = [];

                    for (let i=0; i<target.files.length; i++) {
                        value.push(target.files[i].name);
                    }
                } else {
                    value = target.files[0].name;
                }
                break;
            case 'checkbox':
                value = target.checked;
                break;
            default:
                value = target.value;
                break;
        }

        // Modify our Athlete.
        let updatedAthlete = { ...this.state.athlete };

        // Handle multi-value/nested fields.
        let schoolRecordsRegex = /schoolRecords\[(.*)\]\[(.*)\]/g;
        let schoolRecordsMatches = [ ...name.matchAll(schoolRecordsRegex) ];
        let schoolRecordsMatch = schoolRecordsMatches[0];

        let collegeLocationsRegex = /collegeLocations\[(.*)\]/g;
        let collegeLocationsMatches = [ ...name.matchAll(collegeLocationsRegex) ];
        let collegeLocationsMatch = collegeLocationsMatches[0];

        let guardiansRegex = /guardians\[(.*)\]\[(.*)\]/g;
        let guardiansMatches = [ ...name.matchAll(guardiansRegex) ];
        let guardiansMatch = guardiansMatches[0];

        let endorsementsRegex = /endorsements\[(.*)\]\[(.*)\]/g;
        let endorsementsMatches = [ ...name.matchAll(endorsementsRegex) ];
        let endorsementsMatch = endorsementsMatches[0];

        let tournamentsRegex = /tournaments\[(.*)\]\[(.*)\]/g;
        let tournamentsMatches = [ ...name.matchAll(tournamentsRegex) ];
        let tournamentsMatch = tournamentsMatches[0];

        let filesRegex = /files\[\]/g;
        let filesMatches = [ ...name.matchAll(filesRegex) ];
        let filesMatch = filesMatches[0];

        if (schoolRecordsMatch) {
            if (!updatedAthlete.schoolRecords) {
                updatedAthlete.schoolRecords = {};
            }

            if (!updatedAthlete.schoolRecords[schoolRecordsMatch[1]]) {
                updatedAthlete.schoolRecords[schoolRecordsMatch[1]] = {};
            }

            updatedAthlete.schoolRecords[schoolRecordsMatch[1]][schoolRecordsMatch[2]] = value;
        } else if (collegeLocationsMatch) {
            if (!updatedAthlete.collegeLocations) {
                updatedAthlete.collegeLocations = {};
            }

            updatedAthlete.collegeLocations[collegeLocationsMatch[1]] = value;
        } else if (guardiansMatch) {
            if (!updatedAthlete.guardians) {
                updatedAthlete.guardians = [];
            }

            if (!updatedAthlete.guardians[guardiansMatch[1]]) {
                updatedAthlete.guardians[guardiansMatch[1]] = {};
            }

            updatedAthlete.guardians[guardiansMatch[1]][guardiansMatch[2]] = value;
        } else if (endorsementsMatch) {
            if (!updatedAthlete.endorsements) {
                updatedAthlete.endorsements = [];
            }

            if (!updatedAthlete.endorsements[endorsementsMatch[1]]) {
                updatedAthlete.endorsements[endorsementsMatch[1]] = {};
            }

            updatedAthlete.endorsements[endorsementsMatch[1]][endorsementsMatch[2]] = value;
        } else if (tournamentsMatch) {
            if (!updatedAthlete.tournaments) {
                updatedAthlete.tournaments = [];
            }

            if (!updatedAthlete.tournaments[tournamentsMatch[1]]) {
                updatedAthlete.tournaments[tournamentsMatch[1]] = {};
            }

            updatedAthlete.tournaments[tournamentsMatch[1]][tournamentsMatch[2]] = value;
        } else if (filesMatch) {
            if (!updatedAthlete.files) {
                updatedAthlete.files = [];
            }

            for (let i=0; i<value.length; i++) {
                updatedAthlete.files.push(value[i]);
            }
        } else {
            updatedAthlete[name] = value;
        }

        this.setState({ athlete: updatedAthlete, saved: false, error: false });
    }

    handleAddGuardian = () => {
        let updatedAthlete = { ...this.state.athlete };

        if (!updatedAthlete.guardians) {
            updatedAthlete.guardians = [];
        }

        updatedAthlete.guardians.push({});

        this.setState({ athlete: updatedAthlete });
    }

    handleRemoveGuardian = (index) => {
        let updatedAthlete = { ...this.state.athlete };
        updatedAthlete.guardians = updatedAthlete.guardians.filter((x, i) => i !== index);
        
        this.setState({ athlete: updatedAthlete });
    }

    handleAddEndorsement = () => {
        let updatedAthlete = { ...this.state.athlete };

        if (!updatedAthlete.endorsements) {
            updatedAthlete.endorsements = [];
        }

        updatedAthlete.endorsements.push({});

        this.setState({ athlete: updatedAthlete });
    }

    handleRemoveEndorsement = (index) => {
        let updatedAthlete = { ...this.state.athlete };
        updatedAthlete.endorsements = updatedAthlete.endorsements.filter((x, i) => i !== index);
        
        this.setState({ athlete: updatedAthlete });
    }

    handleAddTournament = () => {
        let updatedAthlete = { ...this.state.athlete };

        if (!updatedAthlete.tournaments) {
            updatedAthlete.tournaments = [];
        }

        updatedAthlete.tournaments.push({});

        this.setState({ athlete: updatedAthlete });
    }

    handleRemoveTournament = (index) => {
        let updatedAthlete = { ...this.state.athlete };
        updatedAthlete.tournaments = updatedAthlete.tournaments.filter((x, i) => i !== index);
        
        this.setState({ athlete: updatedAthlete });
    }

    handleRemoveFile = (index) => {
        let updatedAthlete = { ...this.state.athlete };
        updatedAthlete.files = updatedAthlete.files.filter((x, i) => i !== index);

        this.setState({ athlete: updatedAthlete });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ saving: true, saved: false, error: false });

        try {
            await API.put('AuthenticatedAPI', `/athletes/${this.props.athlete.id}`, {
                body: this.state.athlete
            });

            this.setState({ saving: false, saved: true });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            switch (error.response.status) {
                case 400:
                case 409:
                    this.setState({ saving: false, error: error.response.data.message });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    break;
                default:
                    console.log(error.data.message);
                    break;
            }
        }
    }
}

export default Profile;
