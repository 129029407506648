import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import MUFI from './_images/MUFI.jpg';
import SCTP from './_images/SCTP.png';
import SSSF from './_images/SSSF.jpg';
import USAHighSchool from './_images/USAHighSchool.png';
import USAYESS from './_images/USAYESS.jpg';
import NCSSA from './_images/NCSSAA.jpg';
import EliteShotgun from './_images/Elite-Shotguns.png';

class Partners extends React.Component {
    render() {
        /*
        let usayessCols = ['AK','AL','AZ','CA','FL','GA','ID','KS','MN','MS','NC','NE','NM','NV','OK','OR','SC','SD','TN','TX','UT','WA'].map(state => {
            return (
                <Col xs={6} sm={4} md={3} xl={2} key={state}>
                    <a href={`https://${state}yess.org`} target="_blank" rel="noopener noreferrer">
                        <Image fluid src={`/images/partners/${state}.USAYESS.jpg`} />
                    </a>
                </Col>
            );
        });
        */

        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Partners</h1>

                    <hr/>

                    <Row className="justify-content-center align-items-center">
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><a href='http://www.ncssaa.com/' target='_blank' style={{outlineStyle: 'none'}}><Image src={NCSSA} fluid /></a></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><a href='https://elite-shotguns.com/' target='_blank' style={{outlineStyle: 'none'}}><Image src={EliteShotgun} fluid /></a></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><a href='https://www.midwayusafoundation.org/' target='_blank' style={{outlineStyle: 'none'}}><Image src={MUFI} fluid /></a></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><a href='https://mysctp.com/' target='_blank' style={{outlineStyle: 'none'}}><Image src={SCTP} fluid /></a></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><Image src={SSSF} fluid /></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><Image src={USAHighSchool} fluid /></Col>
                        <Col xs="6" sm="4" md="3" xl="2" className="mb-2 text-center"><a href='https://www.usayess.org/' target='_blank' style={{outlineStyle: 'none'}}><Image src={USAYESS} fluid /></a></Col>
                    </Row>

{/*
                    <h2>USA Youth Education in Shooting Sports</h2>

                    <Row>
                        {usayessCols}
                    </Row>
*/}
                </Container>
            </div>
        );
    }
}

export default Partners;
