import React from 'react'
import Loading from './../Loading';
import ReadOnly from '../Athlete/ReadOnly';
import { API } from 'aws-amplify';

class Athlete extends React.Component {
    state = {
        athlete: null
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            API.get('AuthenticatedAPI', `/athletes/${this.props.match.params.id}`).then(athlete => {
                this.setState({ athlete });
            }).catch(error => {
                console.log(error.responses);
            });
        }
    }

    render() {
        if (this.state.athlete === null) {
            return (
                <Loading />
            );
        }

        return (
            <div className="p-3">
                <ReadOnly athlete={this.state.athlete} />
            </div>
        );
    }
}

export default Athlete;
