import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { API } from 'aws-amplify';

class Profile extends React.Component {
    state = {
        name: null,
        phone: null,
        schoolName: null,
        position: null,
        teamName: null,
        teamAddress: null,
        division: null,
        saving: false,
        saved: false
    }

    constructor(props) {
        super(props);

        this.state.name = props.coach.name || '';
        this.state.phone = props.coach.phone || '';
        this.state.schoolName = props.coach.schoolName || '';
        this.state.position = props.coach.position || '';
        this.state.teamName = props.coach.teamName || '';
        this.state.teamAddress = props.coach.teamAddress || '';
        this.state.division = props.coach.division || '';

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        return (
            <Container className="py-5">
                <h1>My Profile</h1>

                <hr/>

                {this.state.saved && (
                    <Alert variant={'light-blue'}>Profile Updated</Alert>
                )}

                <Form onSubmit={this.handleFormSubmit}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" onChange={this.handleInputChange} value={this.state.name} />
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label>Phone #</Form.Label>
                                <Form.Control type="text" name="phone" onChange={this.handleInputChange} value={this.state.phone} />
                            </Form.Group>

                            <Form.Group controlId="schoolName">
                                <Form.Label>School Name</Form.Label>
                                <Form.Control type="text" name="schoolName" onChange={this.handleInputChange} value={this.state.schoolName} />
                            </Form.Group>

                            <Form.Group controlId="position">
                                <Form.Label>Position</Form.Label><br/>
                                <Form.Check type="radio" inline name="position" label="Head Coach" id="postion-head" checked={this.state.position === 'Head Coach'} onChange={this.handleInputChange} value="Head Coach" />
                                <Form.Check type="radio" inline name="position" label="Assistant Coach" id="postion-assistant" checked={this.state.position === 'Assistant Coach'} onChange={this.handleInputChange} value="Assistant Coach" />
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                            <Form.Group controlId="teamName">
                                <Form.Label>School/Club Team Name</Form.Label>
                                <Form.Control type="text" name="teamName" onChange={this.handleInputChange} value={this.state.teamName} />
                            </Form.Group>

                            <Form.Group controlId="teamAddress">
                                <Form.Label>Team Mailing Address</Form.Label>
                                <Form.Control type="text" name="teamAddress" onChange={this.handleInputChange} value={this.state.teamAddress} />
                            </Form.Group>

                            <Form.Group controlId="division">
                                <Form.Label>Division Team Competes</Form.Label>
                                <Form.Control type="text" name="division" onChange={this.handleInputChange} value={this.state.division} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="blue" type="submit" disabled={this.state.saving}>Update Profile</Button>
                </Form>
            </Container>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value, saved: false });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ saving: true });

        try {
            await API.put('AuthenticatedAPI', `/coaches/${this.props.coach.id}`, {
                body: {
                    name: this.state.name,
                    phone: this.state.phone,
                    schoolName: this.state.schoolName,
                    position: this.state.position,
                    teamName: this.state.teamName,
                    teamAddress: this.state.teamAddress,
                    division: this.state.division
                }
            });

            this.setState({ saving: false, saved: true });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            console.log(error);
            console.log(error.responses);
            return;
        }
    }
}

export default Profile;
