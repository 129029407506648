import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { default as BrochureApp } from './Brochure/App';
import { default as AdminApp } from './Admin/App';
import { default as AthleteApp } from './Athlete/App';
import { default as CoachApp } from './Coach/App';

class App extends React.Component {
    render() {
        return (
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/admin" component={AdminApp} />
                    <Route path="/athlete" component={AthleteApp} />
                    <Route path="/coach" component={CoachApp} />
                    <Route component={BrochureApp} />
                </Switch>
            </Router>
        );
    }
}

export default App;
