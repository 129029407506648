import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

class Endorsements extends React.Component {
    render() {
        let endorsements = this.props.athlete.endorsements || [];

        return (
            <>
                <Row>
                    {endorsements.map((x, i) => {
                        return (
                            <Col xs={12} sm={4} key={i}>
                                <Form.Group controlId={`name${i}`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name={`endorsements[${i}][name]`} onChange={this.props.inputChange} value={endorsements[i].name || ""} />
                                </Form.Group>

                                <Form.Group controlId={`email${i}`}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name={`endorsements[${i}][email]`} onChange={this.props.inputChange} value={endorsements[i].email || ""} />
                                </Form.Group>

                                <Form.Group controlId={`phone${i}`}>
                                    <Form.Label>Phone #</Form.Label>
                                    <Form.Control type="tel" name={`endorsements[${i}][phone]`} onChange={this.props.inputChange} value={endorsements[i].phone || ""} />
                                </Form.Group>

                                <Form.Group controlId={`relationship${i}`}>
                                    <Form.Label>Relationship</Form.Label>
                                    <Form.Control as="select" name={`endorsements[${i}][relationship]`} onChange={this.props.inputChange} value={endorsements[i].relationship || ""}>
                                        <option>Coach</option>
                                        <option>Teacher</option>
                                        <option>Club</option>
                                        <option>Community</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId={`career${i}`}>
                                    <Form.Label>Professional Career</Form.Label>
                                    <Form.Control type="text" name={`endorsements[${i}][career]`} onChange={this.props.inputChange} value={endorsements[i].career || ""} />
                                </Form.Group>

                                <Button size={"sm"} variant="red" className="mb-3" onClick={() => this.props.removeEndorsement(i)}>Remove</Button>
                            </Col>
                        );
                    })}
                </Row>

                {endorsements.length < 6 && (
                    <Button variant="light-blue" onClick={this.props.addEndorsement}>Add Endorsement</Button>
                )}
                <hr/>
            </>
        );
    }
}

export default Endorsements;
