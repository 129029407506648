import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SchoolRecord from './SchoolRecord';
import { Auth, Storage } from 'aws-amplify';

class School extends React.Component {
    state = {
        uploading: null
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Highschool Attended</legend>

                            <Form.Group controlId="highschoolName">
                                <Form.Label><i className="fas fa-building fa-fw"></i> Name</Form.Label>
                                <Form.Control type="text" name="highschoolName" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.highschoolName || ""} />
                            </Form.Group>

                            <Form.Group controlId="highschoolCity">
                                <Form.Label><i className="fas fa-city fa-fw"></i> City</Form.Label>
                                <Form.Control type="text" name="highschoolCity" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.highschoolCity || ""} />
                            </Form.Group>

                            <Form.Group controlId="highschoolState">
                                <Form.Label><i className="fas fa-flag-usa fa-fw"></i> State</Form.Label>
                                <Form.Control type="text" name="highschoolState" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.highschoolState || ""} />
                            </Form.Group>

                            <Form.Group controlId="graduationYear">
                                <Form.Label><i className="fas fa-graduation-cap fa-fw"></i> Graduation Year</Form.Label>
                                <Form.Control type="number" name="graduationYear" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.graduationYear || ""} />
                            </Form.Group>

                            <Form.Group controlId="highschoolCoachEmail">
                                <Form.Label><i className="fas fa-envelope fa-fw"></i> Coach Email</Form.Label>
                                <Form.Control type="email" name="highschoolCoachEmail" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.highschoolCoachEmail || ""} />
                            </Form.Group>

                            <Form.Group controlId="highschoolCoachPhone">
                                <Form.Label><i className="fas fa-phone fa-fw"></i> Coach Phone #</Form.Label>
                                <Form.Control type="text" name="highschoolCoachPhone" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.highschoolCoachPhone || ""} />
                            </Form.Group>
                        </fieldset>
                    </Col>

                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Test Scores</legend>

                            <Form.Group controlId="transcript">
                                <Form.Label><i className="fas fa-scroll fa-fw"></i> Transcript</Form.Label>
                                <Form.File custom name="transcript" label={this.props.athlete.transcript || "Choose a file"} size={"sm"} onChange={this.handleTranscriptChange} accept="application/pdf" />
                                {this.state.uploading !== null ? (
                                    <Form.Text className="text-muted">Upload Progress: {this.state.uploading}%</Form.Text>
                                ) : this.props.athlete.transcript && (
                                    <Form.Text><Button size="sm" variant="link" onClick={this.loadTranscript} className="p-0">View Transcript</Button></Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group controlId="gpa">
                                <Form.Label><i className="fas fa-star fa-fw"></i> GPA</Form.Label>
                                <Form.Control type="number" min="0" max="4" step=".001" name="gpa" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.gpa} />
                            </Form.Group>

                            <Form.Group controlId="sat">
                                <Form.Label><i className="fas fa-star fa-fw"></i> SAT</Form.Label>
                                <Form.Control type="number" name="sat" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.sat} />
                            </Form.Group>

                            <Form.Group controlId="act">
                                <Form.Label><i className="fas fa-star fa-fw"></i> ACT</Form.Label>
                                <Form.Control type="number" name="act" size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.athlete.act} />
                            </Form.Group>
                        </fieldset>
                    </Col>

                    <Col xs={12} sm={4}>
                        <fieldset>
                            <legend>Competed In</legend>

                            <Form.Group>
                                <Form.Check type="checkbox" id="shotgunSuper" name="shotgunSuper" label="Shotgun: Super Sporting" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunSuper} />
                                <Form.Check type="checkbox" id="shotgunClays" name="shotgunClays" label="Shotgun: Sporting Clays" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunClays} />
                                <Form.Check type="checkbox" id="shotgunAmericanSkeet" name="shotgunAmericanSkeet" label="Shotgun American: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunAmericanSkeet} />
                                <Form.Check type="checkbox" id="shotgunAmericanTrap" name="shotgunAmericanTrap" label="Shotgun American: Trap" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunAmericanTrap} />
                                <Form.Check type="checkbox" id="shotgunInternationalSkeet" name="shotgunInternationalSkeet" label="Shotgun International: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunInternationalSkeet} />
                                <Form.Check type="checkbox" id="shotgunInternationalTrap" name="shotgunInternationalTrap" label="Shotgun International: Trap" onChange={this.props.inputChange} defaultChecked={this.props.athlete.shotgunInternationalTrap} />
                                <Form.Check type="checkbox" id="rifle" name="rifle" label="Rifle" onChange={this.props.inputChange} defaultChecked={this.props.athlete.rifle} />
                                <Form.Check type="checkbox" id="pistol" name="pistol" label="Pistol" onChange={this.props.inputChange} defaultChecked={this.props.athlete.pistol} />
                            </Form.Group>
                        </fieldset>

                        <fieldset>
                            <legend>Member Of</legend>

                            <Form.Group>
                                <Form.Check type="checkbox" id="usayess" name="usayess" label="USAYESS" onChange={this.props.inputChange} defaultChecked={this.props.athlete.usayess || false} />
                                <Form.Check type="checkbox" id="nsca" name="nsca" label="NSCA" onChange={this.props.inputChange} defaultChecked={this.props.athlete.nsca || false} />
                                <Form.Check type="checkbox" id="nssa" name="nssa" label="NSSA" onChange={this.props.inputChange} defaultChecked={this.props.athlete.nssa || false} />
                                <Form.Check type="checkbox" id="usa" name="usa" label="USASHOOTING" onChange={this.props.inputChange} defaultChecked={this.props.athlete.usa || false} />
                                <Form.Check type="checkbox" id="sssfSctp" name="sssfSctp" label="SCTP" onChange={this.props.inputChange} defaultChecked={this.props.athlete.sssfSctp || false} />
                                <Form.Check type="checkbox" id="fourH" name="fourH" label="4-H SHOOTING" onChange={this.props.inputChange} defaultChecked={this.props.athlete.fourH || false} />
                                <Form.Check type="checkbox" id="ata" name="ata" label="ATA" onChange={this.props.inputChange} defaultChecked={this.props.athlete.ata || false} />
                                <Form.Check type="checkbox" id="nra" name="nra" label="NRA" onChange={this.props.inputChange} defaultChecked={this.props.athlete.nra || false} />
                            </Form.Group>
                        </fieldset>
                    </Col>
                </Row>

                <hr/>

                <fieldset>
                    <legend>Highschool Record</legend>

                    <Row>
                        {['Freshman','Sophomore','Junior','Senior'].map((value) => {
                            return (
                                <Col xs={12} sm={6} lg={3} key={value}>
                                    <SchoolRecord class={value} schoolRecord={this.props.athlete.schoolRecords[value] || {}} inputChange={this.props.inputChange} />
                                    {value !== 'Senior' && (
                                        <hr className={value === 'Junior' ? 'd-sm-none' : 'd-lg-none'} />
                                    )}
                                </Col>
                            );
                        })}
                    </Row>
                </fieldset>
            </>
        );
    }

    handleTranscriptChange = (event) => {
        event.persist();

        if (event.target.files.length > 0) {
            this.setState({ uploading: 0 });

            const file = event.target.files[0];
            let that = this;

            Storage.put(file.name, file, {
                level: 'protected',
                contentType: file.type,
                progressCallback(progress) {
                    that.setState({ uploading: Math.round(100 * progress.loaded/progress.total) });
                }
            })
            .then(result => {
                this.setState({ uploading: null });
                this.props.inputChange(event);
            })
            .catch(err => console.log(err));
        } else {
            this.props.inputChange(event);
        }
    }

    loadTranscript = () => {
        Auth.currentUserInfo().then(user => {
            Storage.get(this.props.athlete.transcript, {
                level: 'protected',
                identityId: user.id
            })
            .then(result => {
                window.open(result);
            })
            .catch(err => console.log(err));
        });
    }
}

export default School;
