import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navigation from './Navigation';
import Home from './Home';
import Directory from './Directory';
import Parents from './Parents';
import Financial from './Financial';
import ApplicationProcess from './ApplicationProcess';
import Eligibility from './Eligibility';
import About from './About';
import Partners from './Partners';
import Register from './Register';
import Verify from './Verify';
import Login from './Login';
import Reset from './Reset';
import Confirm from './Confirm';
import Terms from './Terms';
import Privacy from './Privacy';
import Cancellation from './Cancellation';
import Missing from '../Missing';
import Footer from './Footer';

class App extends React.Component {
    render() {
        return (
            <div>
                <Navigation />

                <Switch>
                    {/* Brochure Pages */}
                    <Route exact path="/" component={Home} />
                    <Route exact path="/parent-information" component={Parents} />
                    <Route exact path="/financial-aid" component={Financial} />
                    <Route exact path="/college-application-process" component={ApplicationProcess} />
                    <Route exact path="/eligibility" component={Eligibility} />
                    <Route exact path="/directory" component={Directory} />
                    <Route exact path="/about-us" component={About} />
                    <Route exact path="/partners" component={Partners} />

                    {/* Authentication Pages */}
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/verify" component={Verify} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/reset-password" component={Reset} />
                    <Route exact path="/reset-password-confirm" component={Confirm} />

                    {/* Footer Pages */}
                    <Route exact path="/terms-and-conditions" component={Terms} />
                    <Route exact path="/privacy-policy" component={Privacy} />
                    <Route exact path="/cancellation-policy" component={Cancellation} />

                    {/* 404 */}
                    <Route component={Missing} />
                </Switch>

                <Footer />
            </div>
        );
    }
}

export default App;
