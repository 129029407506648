import React from 'react';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth } from 'aws-amplify';

class Verify extends React.Component {
    state = {
        code: '',
        submitting: false,
        error: null,
        message: null,
        redirectToLogin: false
    }

    constructor(props) {
        super(props);

        this._email = this.props.location.state && this.props.location.state.email;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleVerificationRequest = this.handleVerificationRequest.bind(this);
    }

    render() {
        if (!this._email) {
            return <Redirect to="/login" />
        } else if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login', state: { email: this._email, verified: true } }} />
        }

        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Verify Your Account</h1>
                    <p className="lead">You should have received an email at {this._email} containing a verification code. Please enter it here to confirm your account.</p>

                    <hr/>

                    <Row className="justify-content-center">
                        <Col sm={10} md={8} lg={6} xl={4} className="bg-white rounded py-3">
                            <h3>Enter Verification Code</h3>

                            <Form onSubmit={this.handleFormSubmit}>
                                <Form.Group controlId="code">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="text" name="code" required onChange={this.handleInputChange} value={this.state.code} />
                                </Form.Group>

                                {this.state.error && (
                                    <Alert variant="red">{this.state.error}</Alert>
                                )}

                                {this.state.message && (
                                    <Alert variant="light-blue">{this.state.message}</Alert>
                                )}

                                <Button size={"lg"} variant="blue" type="submit" block disabled={this.state.submitting}>Verify Account</Button>
                            </Form>
                        </Col>
                    </Row>

                    <hr/>

                    <p>If you can't find your verification code, click here to <Button variant="link" className="p-0" onClick={this.handleVerificationRequest} disabled={this.state.submitting}>send a new verification code</Button>.</p>
                </Container>
            </div>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ submitting: true, message: null });

        try {
            await Auth.confirmSignUp(this._email, this.state.code);

            this.setState({ redirectToLogin: true });
        } catch (error) {
            switch (error.code) {
                case 'CodeMismatchException':
                    this.setState({ error: error.message, submitting: false });
                    break;
                default:
                    console.log('error signing up:', error);
                    break;
            }
        }
    }

    handleVerificationRequest = async (event) => {
        event.preventDefault();

        this.setState({ submitting: true });

        try {
            await Auth.resendSignUp(this._email);

            this.setState({ error: null, message: `A new verification code has been sent to ${this._email}.`, submitting: false });
        } catch (error) {
            switch (error.code) {
                default:
                    console.log('error requesting verification code:', error);
                    break;
            }
        }
    }
}

export default Verify;
