import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Loading from './../Loading';
import { API } from 'aws-amplify';

class Athletes extends React.Component {
    state = {
        athletes: null
    }

    componentDidMount() {
        API.get('AuthenticatedAPI', '/athletes').then(athletes => {
            this.setState({ athletes });
        }).catch(error => {
            console.log(error.responses);
        });
    }

    render() {
        if (this.state.athletes === null) {
            return (
                <Loading className="vh-100" />
            );
        }

        return (
            <div className="p-3">
                <h1>Athletes</h1>

                <Table striped>
                    <thead className="bg-light-blue text-white">
                        <tr>
                            <th>Athlete</th>
                            <th>Contact</th>
                            <th>Subscription</th>
                            <th>Published</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.athletes.map(x => {
                            return (
                                <tr key={x.id}>
                                    <td>
                                        <Link to={`/admin/athletes/${x.id}`}>{x.name || <span className="text-muted">(no name provided)</span>}</Link>
                                        {x.position !== null && (
                                            <div className="small">{x.position}</div>
                                        )}
                                        {x.schoolName !== null && (
                                            <div className="small">{x.schoolName}</div>
                                        )}
                                    </td>
                                    <td>
                                        <a href={`mailto:${x.email}`} className="small"><i className="fa fa-envelope fa-fw"></i> {x.email}</a><br/>
                                        {x.phone !== null && (
                                            <a href={`tel:${x.phone}`} className="small"><i className="fa fa-phone fa-fw"></i> {x.phone}</a>
                                        )}
                                    </td>
                                    <td>
                                        <i className={`fa fa-fw ${x.subscriptionId ? 'fa-check-circle text-success' : 'fa-times-circle text-red'}`}></i>
                                    </td>
                                    <td>
                                        <i className={`fa fa-fw ${x.published ? 'fa-check-circle text-success' : 'fa-times-circle text-red'}`}></i>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default Athletes;
