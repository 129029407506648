import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

class Tournaments extends React.Component {
    render() {
        let tournaments = this.props.athlete.tournaments || [];

        return (
            <>
                <Row>
                    {tournaments.map((x, i) => {
                        let eventDate = new Date(x.eventDate);
                        let eventDateString = [
                            eventDate.getUTCFullYear(),
                            (eventDate.getUTCMonth() + 1).toString().padStart(2, '0'),
                            eventDate.getUTCDate().toString().padStart(2, '0')
                        ].join('-');

                        return (
                            <Col xs={12} sm={4} key={i}>
                                <Form.Group controlId={`eventName${i}`}>
                                    <Form.Label>Event Name</Form.Label>
                                    <Form.Control type="text" name={`tournaments[${i}][eventName]`} onChange={this.props.inputChange} defaultValue={tournaments[i].eventName || ""} />
                                </Form.Group>

                                <Form.Group controlId={`eventDate${i}`}>
                                    <Form.Label>Event Date</Form.Label>
                                    <Form.Control type="date" name={`tournaments[${i}][eventDate]`} onChange={this.props.inputChange} defaultValue={eventDateString || ""} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Competed In</Form.Label>
                                    <Form.Check type="checkbox" id={`tournaments[${i}][americanSkeet]`} name={`tournaments[${i}][americanSkeet]`} label="Shotgun American: Skeet" onChange={this.props.inputChange} defaultChecked={tournaments[i].americanSkeet} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][americanTrap]`} name={`tournaments[${i}][americanTrap]`} label="Shotgun American: Trap" onChange={this.props.inputChange} defaultChecked={tournaments[i].americanTrap} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][internationalSkeet]`} name={`tournaments[${i}][internationalSkeet]`} label="Shotgun International: Skeet" onChange={this.props.inputChange} defaultChecked={tournaments[i].internationalSkeet} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][internationalTrap]`} name={`tournaments[${i}][internationalTrap]`} label="Shotgun International: Trap" onChange={this.props.inputChange} defaultChecked={tournaments[i].internationalTrap} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][sportingClay]`} name={`tournaments[${i}][sportingClay]`} label="Shotgun: Sporting Clays" onChange={this.props.inputChange} defaultChecked={tournaments[i].sportingClay} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][superSporting]`} name={`tournaments[${i}][superSporting]`} label="Shotgun: Super Sporting" onChange={this.props.inputChange} defaultChecked={tournaments[i].superSporting} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][rifle]`} name={`tournaments[${i}][rifle]`} label="Rifle" onChange={this.props.inputChange} defaultChecked={tournaments[i].rifle} />
                                    <Form.Check type="checkbox" id={`tournaments[${i}][pistol]`} name={`tournaments[${i}][pistol]`} label="Pistol" onChange={this.props.inputChange} defaultChecked={tournaments[i].pistol} />
                                </Form.Group>

                                <Form.Group controlId={`outcome${i}`}>
                                    <Form.Label>Outcome</Form.Label>
                                    <Form.Control as="select" name={`tournaments[${i}][outcome]`} onChange={this.props.inputChange} defaultValue={tournaments[i].outcome || ""}>
                                        <option value="0">None</option>
                                        <option value="1">Win</option>
                                        <option value="2">Placement</option>
                                        <option value="3">HOA</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Label><i className="fas fa-ruler fa-fw"></i> Gauges Shot</Form.Label>
                                <Form.Group>
                                    <Form.Check type="checkbox" inline id={`tournaments[${i}][gauge12]`} name={`tournaments[${i}][gauge12]`} label="12" onChange={this.props.inputChange} defaultChecked={tournaments[i].gauge12} />
                                    <Form.Check type="checkbox" inline id={`tournaments[${i}][gauge20]`} name={`tournaments[${i}][gauge20]`} label="20" onChange={this.props.inputChange} defaultChecked={tournaments[i].gauge20} />
                                    <Form.Check type="checkbox" inline id={`tournaments[${i}][gauge28]`} name={`tournaments[${i}][gauge28]`} label="28" onChange={this.props.inputChange} defaultChecked={tournaments[i].gauge28} />
                                    <Form.Check type="checkbox" inline id={`tournaments[${i}][gauge410]`} name={`tournaments[${i}][gauge410]`} label="410" onChange={this.props.inputChange} defaultChecked={tournaments[i].gauge410} />
                                </Form.Group>

                                <Button size={"sm"} variant="red" className="mb-3" onClick={() => this.props.removeTournament(i)}>Remove</Button>
                            </Col>
                        );
                    })}
                </Row>

                {tournaments.length < 6 && (
                    <Button variant="light-blue" onClick={this.props.addTournament}>Add Tournament</Button>
                )}
                <hr/>
            </>
        );
    }
}

export default Tournaments;
