import React from 'react';
import Container from 'react-bootstrap/Container';

class Parents extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Parent Information</h1>

                    <hr/>

                    <p>There is not one right way to help your child prepare for college orcomplete the college application process. Like all things in parenting, thistoo will depend on the individual child. However, even though somestudents are going to need much more assistance than others, they will allbenefit from your involvement.</p>
                    <p>Here is a list of important roles that you can play as a parent/guardian:</p>
            
                    <ol>
                        <li>Encourage your student to get involved in extracurricular activities that match their interests and hobbies</li>
                        <li>Support your child in fulfilling academic requirements and succeeding in school</li>
                        <li>Help them seek out leadership roles</li>
                        <li>Preparing for and signing up for standardized tests (SAT/ACT)</li>
                        <li>Get an early start on researching college options, set up college visits</li>
                        <li>Assist them in keeping track of deadlines and important dates</li>
                        <li>Proofread and edit essays</li>
                        <li>Provide as much financial support as you can</li>
                        <li>Be your child’s cheerleader and help them find the best college “fit”</li>
                    </ol>
            
                    <h2>Resources for parents</h2>

                    <ul>
                        <li>School guidance counselors</li>
                        <li>College admissions offices</li>
                        <li>College fairs</li>
                        <li>Helpful links (under the “College Application Process” tab)</li>
                        <li>Other parents in the community</li>
                        <li>College Planning for Dummies by Pat Ordovensky</li>
                    </ul>
            
                    <a href="http://media.collegeboard.com/digitalServices/pdf/student/college-application-timeline.pdf" target="_blank" rel="noopener noreferrer">College Application Timeline by The College Board</a>
                </Container>
            </div>
        );
    }
}

export default Parents;
