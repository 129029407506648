import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import collegeApplicationChecklist from './_docs/college-application-checklist.pdf';
import helpfulLinks from './_docs/helpful-links.pdf';

class ApplicationProcess extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>College Application Process</h1>

                    <hr/>

                    <p>Use this college application checklist to assist you in staying organized and on top of this important and tedious process! It is important that you write down the month and year that you would like to have things accomplished (your GOAL DATE!) We also recommend that you write down the date that you actually completed the task (your COMPLETION DATE!)</p>

                    <Button variant="light-blue" href={collegeApplicationChecklist} target="_blank">Download College Application Checklist</Button>

                    <hr/>

                    <h2>The Common Application</h2>
                    <p>When you're ready, make your college application process even easier by using Common App.</p>

                    <Button variant="light-blue" href="https://www.commonapp.org/" target="_blank">Apply with Common App</Button>

                    <hr/>

                    <h2>Helpful Links</h2>
                    <p>We hope these links are helpful to you during your college application process! Print this and keep it in your college application portfolio/folder.</p>

                    <Button variant="light-blue" href={helpfulLinks} target="_blank">Download Helpful Links</Button>
                </Container>
            </div>
        );
    }
}

export default ApplicationProcess;
