import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Home extends React.Component {
    state = {
        name: '',
        email: '',
        message: ''
    };

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        return (
            <>
                <div id="home-banner" className="py-5">
                    <Container className="py-5 text-white text-shadow">
                        <h1 className="display-3 font-weight-normal">College Shooting Sports Recruiting</h1>
                        <hr className="border-white" />
                        <p className="h3 mb-4">A Profile Platform to help you in your search for the right fit in academics and your goal to Compete in Shooting Sports at the college level.</p>

                        <Button size={"lg"} variant="red" as={Link} to="/register" href="/register" className="mr-3">Sign Up</Button>
                        <Button size={"lg"} variant="gray" as={Link} to="/login" href="/login">Sign In</Button>
                    </Container>
                </div>

                <div id="home-banner-2" className="py-5 border-top border-white">
                    <Container className="py-5 text-white text-center text-shadow">
                        <h1 className="display-4 font-weight-normal">GET COMMITTED!</h1>
                        <p className="h3">Shooters helping Shooters to compete at the college level. Start the recruiting process TODAY! Let College Shooting Sports Recruiting help reach college coaches.</p>

                        <hr className="border-white my-5" />

                        <h2 className="h1">HOW CAN WE HELP YOU?</h2>
                        <p className="lead">We are Shooters! We understand the recruiting process. We help build your profile that will be seen by many coaches. If you have the desire to compete in Shooting Sports in college, we highly believe there is a place to for you to Shoot. We do not promise full ride scholarships or scholarships of any size for anyone. The coaches and their institutions dictate what is available within their guidelines. You may be a Shooter that could receive scholarship money in many different avenues, you may be a Shooter that can receive a straight roster spot on the team. We will help you get exposed to the coaches and programs to compete at the college level. There are so many opportunities. We can help you understand what fits best for you. If you are committed to Shooting Sports at the college level, we will do our best to help you get there.</p>
                    </Container>
                </div>

                <div className="bg-gray py-5">
                    <Container>
                        <Row className="align-items-center">
                            <Col sm={"auto"} className="text-center"><i className="far fa-id-card fa-fw fa-6x text-blue"></i></Col>
                            <Col>
                                <h1>CONNECT WITH COLLEGE COACHES, GET RECRUITED!</h1>
                                <p className="lead mb-0">College Shooting Sports Recruiting has built a detailed questionnaire that will complete your unique profile package for coaches to be able to search. Coaches will be able to review your accomplishments, goals, and aspirations. Fill in the blanks as outlined throughout the profile builder. You will also be able to upload transcripts, photos, and highlight video to your profile.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div id="home-banner-3">
                    <Container className="py-5 text-white text-shadow">
                        <div className="text-center mb-5">
                            <h1>IT’S YOUR TIME. GET STARTED!</h1>
                            <p className="h5">Let’s go! It is time to take that Shot. We are here to help get your name out there. It's your time!</p>
                        </div>

                        <Row className="justify-content-around align-items-center">
                            <Col className="text-center mb-5 mb-md-0" xs={12} md={6} lg={5} xl={4}>
                                <div className="display-1">$59</div>
                                <div className="h2">Annually</div>
                                <p className="lead">1 time fee for full year subscription</p>
                                <Button size={"lg"} variant="red">Get Started!</Button>
                            </Col>
                            <Col xs={12} sm={10} md={6} lg={5} xl={4} id="contact-form">
                                <h1>
                                    Have Questions?<br/>
                                    <small>Get in touch with us!</small>
                                </h1>
                                
                                <Form onSubmit={this.handleFormSubmit}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Your name" required value={this.state.name} onChange={this.handleInputChange} />
                                    </Form.Group>

                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" placeholder="email@address.com" required value={this.state.email} onChange={this.handleInputChange} />
                                    </Form.Group>

                                    <Form.Group controlId="message">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows="3" name="message" placeholder="What do you want to ask?" required value={this.state.message} onChange={this.handleInputChange} />
                                    </Form.Group>

                                    <Button size={"lg"} variant="light-blue" type="submit" block>Send</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let email = 'info@levelup-Athletics.com';
        let subject = 'College Shooting Sports: Contact Form';
        let body = `<strong>Name:</strong> ${this.state.name}<br/><strong>Email:</strong> ${this.state.email}<br/><strong>Message:</strong> ${this.state.message}`;

        window.location.href = encodeURI(`mailto:${email}?subject=${subject}&body=${body}`);
    }
}

export default Home;
