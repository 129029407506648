import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from './Navigation';
import Home from './Home';
import Athletes from './Athletes';
import Athlete from './Athlete';
import Coaches from './Coaches';
import { Auth } from 'aws-amplify';

class App extends React.Component {
    state = {
        redirectToLogin: false
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(user => {
            if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admin') {
                this.setState({ redirectToLogin: true });
            }
        }).catch(error => {
            this.setState({ redirectToLogin: true });
        });
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login' }} />
        }

        return (
            <Row noGutters={true}>
                <Col xs={12} sm={"auto"} className="min-sm-vh-100 bg-blue">
                    <Navigation />
                </Col>

                <Col className="bg-gray">
                    <Switch>
                        <Route exact path="/admin" component={Home} />
                        <Route exact path="/admin/athletes" component={Athletes} />
                        <Route path="/admin/athletes/:id" component={Athlete} />
                        <Route exact path="/admin/coaches" component={Coaches} />
                    </Switch>
                </Col>
            </Row>
        );
    }
}

export default App;
