import { Auth } from 'aws-amplify';

const awsImports = {
    API: {
        endpoints: [{
            name: "UnauthenticatedAPI",
            endpoint: "https://eituhllg94.execute-api.us-east-2.amazonaws.com/live"
        }, {
            name: "AuthenticatedAPI",
            endpoint: "https://eituhllg94.execute-api.us-east-2.amazonaws.com/live",
            custom_header: async () => {
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }]
    }
};

export default awsImports;
