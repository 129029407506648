import React from 'react';

class Loading extends React.Component {
    render() {
        return (
            <div className={`d-flex justify-content-center align-items-center ${this.props.className}`}>
                <i className="fas fa-spinner fa-fw fa-spin fa-5x text-blue"></i>
                <h1 className="display-3 mb-0">Loading...</h1>
            </div>
        );
    }
}

export default Loading;
