import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

class Guardians extends React.Component {
    render() {
        let guardians = this.props.athlete.guardians || [];

        return (
            <>
                <Row>
                    {guardians.map((x, i) => {
                        return (
                            <Col xs={12} sm={4} key={i}>
                                <Form.Group controlId={`name${i}`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name={`guardians[${i}][name]`} onChange={this.props.inputChange} value={guardians[i].name || ""} />
                                </Form.Group>

                                <Form.Group controlId={`email${i}`}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name={`guardians[${i}][email]`} onChange={this.props.inputChange} value={guardians[i].email || ""} />
                                </Form.Group>

                                <Form.Group controlId={`phone${i}`}>
                                    <Form.Label>Phone #</Form.Label>
                                    <Form.Control type="tel" name={`guardians[${i}][phone]`} onChange={this.props.inputChange} value={guardians[i].phone || ""} />
                                </Form.Group>

                                <Form.Group controlId={`address${i}`}>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name={`guardians[${i}][address]`} onChange={this.props.inputChange} value={guardians[i].address || ""} />
                                </Form.Group>

                                <Form.Group controlId={`relationship${i}`}>
                                    <Form.Label>Relationship</Form.Label>
                                    <Form.Control type="text" name={`guardians[${i}][relationship]`} onChange={this.props.inputChange} value={guardians[i].relationship || ""} />
                                </Form.Group>

                                <Button size={"sm"} variant="red" className="mb-3" onClick={() => this.props.removeGuardian(i)}>Remove</Button>
                            </Col>
                        );
                    })}
                </Row>

                {guardians.length < 3 && (
                    <Button variant="light-blue" onClick={this.props.addGuardian}>Add Guardian</Button>
                )}
                <hr/>
            </>
        );
    }
}

export default Guardians;
