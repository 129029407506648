import React from 'react';
import Container from 'react-bootstrap/Container';

class About extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>About Us</h1>

                    <hr/>

                    <p>We are dedicated business professionals working within sport specific athletic arenas. We have real competition experiences within the sports we help you get recruited in. We want to try and help student athletes and parents get through the &ldquo;muddy recruiting&rdquo; process. This often gets so confusing that the student athlete and or parents get lost and give up on a passion and a dream of competing at the college level.</p>
                </Container>
            </div>
        );
    }
}

export default About;
