import React from 'react';
import Container from 'react-bootstrap/Container';

class Eligibility extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Eligiblity Requirements</h1>

                    <hr/>

                    <h2>Division I Education Requirements</h2>
                    <ul>
                        <li>Graduate high school</li>
                        <li>Earn a core course GPA of 2.3 or higher</li>
                        <li>Complete 16 core courses</li>
                        <li>4 years of English</li>
                        <li>3 years of math (Algebra 1 or higher)</li>
                        <li>2 years of natural/physical science1 year must be lab science if your school offers it</li>
                        <li>1 additional year of English, math or natural/physical science</li>
                        <li>2 years of social science</li>
                        <li>4 additional years of English, math, natural/physical science, social science, foreign language, comparative religion or philosophy</li>
                        <li>You must complete 10 of the core courses by the end of your junior year (before the start of your seventh semester). Seven of the 10 core courses need to be in English, math or natural/physical science. The grades in these seven courses will be “locked in,” meaning you will not be allowed to retake them toimprove your grades.</li>
                    </ul>
            
                    <hr/>

                    <h2>Division II Education Requirements</h2>
                    <ul>
                        <li>Graduate high school</li>
                        <li>Earn a core course GPA of 2.2 of higher</li>
                        <li>Complete 16 core courses</li>
                        <li>3 years of English</li>
                        <li>2 years of math (Algebra 1 or higher)</li>
                        <li>2 years of natural/physical science1 year must be lab science if your school offers it</li>
                        <li>3 additional year of English, math or natural/physical science</li>
                        <li>2 years of social science</li>
                        <li>4 additional years of English, math, natural/physical science, social science, foreign language, comparative religion or philosophy</li>
                    </ul>

                    <hr/>

                    <h2>Division III Education Requirements</h2>
                    <p>Division III schools are responsible for setting their own academic eligibility rules. It is recommended that each potential student-athlete discuss their athletic eligibility with the athletic personnel. We would still strongly suggest registering with the NCAA Eligibility Center.</p>
                    
                    <hr/>

                    <h2>NAIA Freshman Eligibility</h2>
                    <p><strong>Freshman:</strong> A student who has not been previously identified at any institution of higher learning for 2 semesters/3 quarters.</p>
                    <p>To be eligible for competition, a freshman must:</p>

                    <ul>
                        <li>Graduate of accredited high school or be accepted as regular student in good standing</li>
                        <li>AND meet two of three requirements:
                            <ol>
                                <li>Test Score Minimum score of: ACT — 18 / SAT — 860 (Critical Reading and Math Sections only)</li>
                                <li>High School GPA Minimum overall high school GPA of 2.000 on 4.000 scale</li>
                                <li>Class Rank Top 50% of high school graduating class</li>
                            </ol>
                        </li>
                    </ul>
                    
                    <hr/>

                    <h3>ACUI Student Program Eligibility &amp; Code of Conduct</h3>
                    <p>ACUI was founded in 1914. ACUI is a nonprofit educational organization that brings together college union and student activities professionals from more than 500 colleges and universities in eight regions and seven countries. ACUI reaches future leaders in the profession and in society through programs designed to appeal to a wide variety of student interests. If you are looking for leadership development, peer-to-peer networking, or a chance to compete in recreation and leisure activities, these programs might be exactly what you need. Recognizing that the primary purpose of student life on campus is academic achievement, to further underscore the importance of continuing academic progress, students participating in ACUI student programs must be enrolled as a student in good standing at the institution they represent. To that end, ACUI hereby establishes the below expectations for students participating in its programs.</p>

                    <h4>Eligibility Requirements</h4>
                    <ol>
                        <li>All participants must meet eligibility criteria established by their school.</li>
                        <li>All participants are limited to six years of eligibility. Students enrolled at two-year institutions are limited to three years of eligibility; if they transfer to a four-year school, six years total.</li>
                        <li>All undergraduate participants must have a cumulative GPA of 2.0 as of the academic term in which they qualified for program; for graduate students, 3.0 cumulative GPA.</li>
                        <li>All participants must be enrolled as of the academic term in which they qualified for the program for at least nine degree-seeking credit hours (six credits for graduate students) and must complete six credit hours between all program appearances.</li>
                        <li>All undergraduate and graduate student participants must be enrolled as a student in good conduct standing at the institution they represent, as determined by the institution in which they are currently enrolled.</li>
                        <li>For participation in championship-level competitions, certification of eligibility must be made by an advisor or authorized staff member of the school. All participants in international-level competitions are required to provide registrar’s seal and signature.</li>
                    </ol>
            
                    <h4>Code of Conduct Expectations</h4>
                    <ol>
                        <li>Students are expected to review all advanced mailings and material received regarding the program. Information should also be shared with their campus advisor.</li>
                        <li>Access to cell phones, pagers, mobile devices, and electronic music devices is subject to the event coordinator’s discretion during the competition or program sessions.</li>
                        <li>Negative posts, belittling accomplishments, bullying, posting of denigrating comments, or impersonating others in public or social media that creates a hostile environment within our otherwise inclusive community are a violation of the ACUI Code of Conduct in place for all ACUI student programs. This code explicitly states that as a participant it is an obligation to abide by the conduct guidelines and that violation of them may jeopardize one’s status as a participant in this program.</li>
                        <li>Drinking and illegal drugs are not allowed during programs regardless of the students’ age.</li>
                        <li>No smoking, e-cigarettes/hookah, or chewing tobacco is allowed on the field.</li>
                        <li>Come prepared with a positive attitude, a willingness to take risks, and a desire to have fun.</li>
                        <li>Respect and uphold ACUI policies and philosophies of advancing campus community builders through unconditional human worth, joy, learning, caring community, innovation, diversity, and integrity.</li>
                        <li>Participants agree to abide by any additional regulations laid out by the host range.</li>
                        <li>ACUI supports the filming of the event, and an athlete’s participation or a spectator’s attendance grants ACUI permission to use their image. That image can be used for reproduction in any media and for promotional purposes of the sport and the ACUI tournament.</li>
                        <li>Participants are representing their institutions and the Association. All participants should adhere to the dress code for their respective events. The dress code is designed to provide for safety, support an environment conducive to all learning, respect individuality, and preserve the dignity of each student who is able to participate.</li>
                        <li>ACUI expects participation to be based on one’s self-identified gender and that it is done in good faith and is consistent with a player’s expressed gender identity. A participant’s gender identity will be applied when there are gender-specific rules or player ratio requirements for co-rec divisions. Transgender individuals may compete in the division that best matches their self-identified gender identity. The campus official or advisor who approves registration for the event should verify the gender is based on the participant’s self-identification and expressed gender identity, not purely on the sex indicated in official school records.</li>
                    </ol>
            
                    <h4>Participants are also expected to:</h4>
                    <ol>
                        <li>Refer to the 2020 match program regarding specific tournament information and guidelines.</li>
                        <li>Adhere to all safety guidelines outlined in the program and reviewed at competitors’ meetings.</li>
                        <li>Agree to be a part of the community and upholding the responsibilities as a member.</li>
                    </ol>

                    <hr/>

                    <h3>NJCAA Eligibility</h3>
                    <p>Due to the unique academic and athletic situation of each individual, and the complexity of the NJCAA eligibility rules, it is recommended that each potential student-athlete discuss their athletic eligibility with the athletic personnel at the NJCAA college where they have chosen to attend. Should the athletic staff have any questions in determining an individual's eligibility, the college may contact the NJCAA National Office for assistance. A student-athlete must be a graduate of a high school with an academic diploma, general education diploma or a state department of education with an approved high school equivalency test.</p>
                </Container>
            </div>
        );
    }
}

export default Eligibility;
