import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

class Navigation extends React.Component {
    render() {
        return (
            <div className="bg-blue">
                <Container>
                    <Navbar variant="dark" expand="lg">
                        <Navbar.Brand as={Link} to="/" href="/"><Image src="/images/logo-xs.png" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="primary-navigation" />

                        <Navbar.Collapse id="primary-navigation">
                            <Nav activeKey={this.props.location.pathname} className="w-100">
                                <Nav.Link as={Link} to="/" href="/">Home</Nav.Link>
                                <NavDropdown title="Recruits">
                                    <NavDropdown.Item as={Link} to="/parent-information" href="/parent-information">Parent Information</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/financial-aid" href="/financial-aid">Financial Aid</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/college-application-process" href="/college-application-process">College Application Process</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/eligibility" href="/eligibility">Eligibility</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={Link} to="/directory" href="/directory">Directory</Nav.Link>
                                <NavDropdown title="Who's Who">
                                    <NavDropdown.Item as={Link} to="/about-us" href="/about-us">About Us</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/partners" href="/partners">Partners</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="More Info" className="mr-auto">
                                    <NavDropdown.Item as={Link} to="/terms-and-conditions" href="/terms-and-conditions">Terms &amp; Conditions</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/privacy-policy" href="/privacy-policy">Privacy Policy</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cancellation-policy" href="/cancellation-policy">Cancellation Policy</NavDropdown.Item>
                                </NavDropdown>
                                <Button variant="red" as={Link} to="/register" href="/register" className="mr-lg-2">Sign Up</Button>
                                <Button variant="gray" as={Link} to="/login" href="/login">Sign In</Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </div>
        );
    }
}

export default withRouter(Navigation);
