import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Figure from 'react-bootstrap/Figure';
import Button from 'react-bootstrap/Button';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import { Auth, Storage } from 'aws-amplify';

class Media extends React.Component {
    state = {
        uploading: null,
        filePaths: null,
        tooManyFiles: false
    }

    constructor(props) {
        super(props);

        this.loadFiles();
    }

    render() {
        let files = this.props.athlete.files || [];

        return (
            <>
                {files.length < 10 && (
                    <Row>
                        <Col xs={"auto"}>
                            <Form.Group controlId="media">
                                <Form.Label>Upload Media</Form.Label>
                                <Form.File custom multiple name="files[]" label="Choose files" size={"sm"} onChange={this.handleFilesChange} accept="image/png, image/jpeg, image/gif, video/mp4" />
                                {this.state.tooManyFiles && (
                                    <Form.Text className="text-red">You can only upload 10 media files.</Form.Text>
                                )}
                                {this.state.uploading !== null && this.state.uploading.map((x, i) => {
                                        return (
                                            <Form.Text key={i} className="text-muted">Upload Progress: {x}%</Form.Text>
                                        );
                                })}
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                <Row>
                    {files.map((x, i) => {
                        return (
                            <Col xs={12} sm={6} md={4} lg={3} key={i}>
                                <Figure className="d-block">
                                    {this.state.filePaths && (this.state.filePaths[i] && this.state.filePaths[i].split('?')[0].endsWith('mp4') ? (
                                        <ResponsiveEmbed aspectRatio="16by9">
                                            <video controls>
                                                <source src={this.state.filePaths[i]} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </ResponsiveEmbed>
                                    ) : (
                                        <Figure.Image fluid thumbnail src={this.state.filePaths[i]} />
                                    ))}
                                    <Figure.Caption>{x}</Figure.Caption>
                                </Figure>

                                <Button size={"sm"} variant="red" className="mb-3" onClick={() => this.handleRemoveFile(i)}>Remove</Button>
                            </Col>
                        );
                    })}
                </Row>
            </>
        );
    }

    handleFilesChange = (event) => {
        if (event.target.files.length + this.props.athlete.files.length > 10) {
            this.setState({ tooManyFiles: true });
            return;
        } else {
            this.setState({ tooManyFiles: false });
        }

        event.persist();

        if (event.target.files.length > 0) {
            this.setState({ uploading: Array(event.target.files.length) });

            let that = this;
            let promises = [];

            for (let i=0; i<event.target.files.length; i++) {
                const file = event.target.files[i];

                promises.push(Storage.put(file.name, file, {
                    level: 'protected',
                    contentType: file.type,
                    progressCallback(progress) {
                        let uploading = [ ...that.state.uploading ];
                        uploading[i] = Math.round(100 * progress.loaded/progress.total);
                        that.setState({ uploading });
                    }
                }));
            }

            Promise.all(promises).then(result => {
                this.setState({ uploading: null });
                this.props.inputChange(event);
                this.loadFiles();
            })
            .catch(err => console.log(err));
        } else {
            this.props.inputChange(event);
        }
    }

    loadFiles = () => {
        Auth.currentUserInfo().then(user => {
            let promises = [];

            for (let i=0; i<this.props.athlete.files.length; i++) {
                promises.push(
                    Storage.get(this.props.athlete.files[i], {
                        level: 'protected',
                        identityId: user.id
                    })
                );
            }

            Promise.all(promises)
            .then(results => {
                this.setState({ filePaths: results });
            })
            .catch(err => console.log(err));
        });
    }

    handleRemoveFile = index => {
        this.setState({ filePaths: this.state.filePaths.filter((x, i) => i !== index) });
        this.props.removeFile(index);
    }
}

export default Media;
