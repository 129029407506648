import React from 'react';
import Container from 'react-bootstrap/Container';

class Cancellation extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Directory of Active College Clay Teams</h1>

                    <hr/>

                    <iframe title="College Shooting Sports Recruiter | Map of Active College Clay Teams" src="https://www.google.com/maps/d/u/2/embed?mid=1YM2-_BXNk9VhIgjbyShSLqzF87FVqYod" width="100%" className="vh-100"></iframe>
                </Container>
            </div>
        );
    }
}

export default Cancellation;
