import React from 'react';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth } from 'aws-amplify';

class Reset extends React.Component {
    state = {
        email: '',
        submitting: false,
        error: null,
        redirectToConfirm: false
    }

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        if (this.state.redirectToConfirm) {
            return <Redirect to={{ pathname: '/reset-password-confirm', state: { email: this.state.email } }} />
        }

        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Reset Your Password</h1>
                    <p className="lead">Use the form below to reset your password.</p>

                    <hr/>

                    <Row className="justify-content-center">
                        <Col sm={10} md={8} lg={6} xl={4} className="bg-white rounded py-3">
                            <h3>Enter Your Email</h3>

                            <Form onSubmit={this.handleFormSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" required onChange={this.handleInputChange} value={this.state.email} />
                                </Form.Group>

                                {this.state.error && (
                                    <Alert variant="red">{this.state.error}</Alert>
                                )}

                                <Button size={"lg"} variant="blue" type="submit" block disabled={this.state.submitting}>Reset Password</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ submitting: true });

        try {
            await Auth.forgotPassword(this.state.email);

            this.setState({ redirectToConfirm: true });
        } catch (error) {
            switch (error.code) {
                case 'UserNotFoundException':
                    this.setState({ error: 'No account found with this email.', submitting: false });
                    break;
                default:
                    console.log('error resetting password:', error);
                    break;
            }
        }
    }
}

export default Reset;
