import React from 'react';
import Container from 'react-bootstrap/Container';

class Missing extends React.Component {
    render() {
        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Page Not Found</h1>

                    <hr/>

                    <p>We cannot find the page you are looking for.</p>
                </Container>
            </div>
        );
    }
}

export default Missing;
