import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import { Storage } from 'aws-amplify';
import CollegeLocations from './CollegeLocations';

class ReadOnly extends React.Component {
    state = {
        headshotFilePath: null,
        filePaths: [],
        transcriptFilePath: null
    }

    _initialBirthday;
    _tournamentEventDates;

    constructor(props) {
        super(props);

        if (props.athlete.birthday) {
            let birthdayDate = new Date(props.athlete.birthday);

            if (!isNaN(birthdayDate)) {
                this._initialBirthday = [
                    birthdayDate.getUTCFullYear(),
                    (birthdayDate.getUTCMonth() + 1).toString().padStart(2, '0'),
                    birthdayDate.getUTCDate().toString().padStart(2, '0')
                ].join('-');
            }
        }

        if (props.athlete.headshot) {
            this.getFilePath('headshotFilePath', props.athlete.headshot);
        }

        if (props.athlete.tournaments && props.athlete.tournaments.length > 0) {
            this._tournamentEventDates = props.athlete.tournaments.map(x => {
                let utcDate;
                
                if (x.eventDate !== null) {
                    utcDate = new Date(x.eventDate);
                }

                if (!isNaN(utcDate)) {
                    return [
                        utcDate.getUTCFullYear(),
                        (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'),
                        utcDate.getUTCDate().toString().padStart(2, '0')
                    ].join('-');
                }

                return '';
            });
        }

        if (props.athlete.files && props.athlete.files.length > 0) {
            this.getFilePaths('filePaths', props.athlete.files);
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h1>{this.props.athlete.name || '(no name provided)'}</h1>
                        {this.props.athlete.committed == true && (
                            <h3 className="text-red"><i className="fas fa-university fa-fw"></i> Committed</h3>
                        )}
                        <p className="lead">{this.props.athlete.summary}</p>
                    </Col>
                    {this.state.headshotFilePath !== null && (
                        <Col xs={8} sm={4} md={4} lg={3}>
                            <Image fluid thumbnail src={this.state.headshotFilePath} />
                        </Col>
                    )}
                </Row>

                {this.state.filePaths && this.state.filePaths.length > 0 && (
                    <>
                        <hr/>

                        <Carousel>
                            {this.state.filePaths.map((x, i) => {
                                return (
                                    <Carousel.Item className="bg-dark text-center" key={i}>
                                        {this.state.filePaths && (this.state.filePaths[i] && this.state.filePaths[i].split('?')[0].endsWith('mp4') ? (
                                            <ResponsiveEmbed aspectRatio="16by9" className="mx-auto carouselFileUpload">
                                                <video controls>
                                                    <source src={this.state.filePaths[i]} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </ResponsiveEmbed>
                                        ) : (
                                            <Image fluid src={this.state.filePaths[i]} className="carouselFileUpload" />
                                        ))}
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </>
                )}

                <hr/>

                <Row>
                    <Col xs={12} sm={4}>
                        <h3>Contact Info</h3>
                        <p>
                            <strong>Email:</strong> {this.props.athlete.email}<br/>
                            <strong>Phone:</strong> {this.props.athlete.phone}<br/>
                        </p>

                        <p>
                            <strong>Address:</strong> {this.props.athlete.address}<br/>
                            <strong>City:</strong> {this.props.athlete.city}<br/>
                            <strong>State:</strong> {this.props.athlete.state}<br/>
                        </p>
                    </Col>
                    <Col xs={12} sm={4}>
                        <h3>Demographics</h3>
                        <p>
                            <strong>Birthday:</strong> {this._initialBirthday}
                        </p>

                        <p>
                            <strong>Height (inches):</strong> {this.props.athlete.height}<br/>
                            <strong>Weight (pounds):</strong> {this.props.athlete.weight}
                        </p>

                        <p>
                            <strong>Ethnicity:</strong> {this.props.athlete.ethnicity}<br/>
                            <strong>Religion:</strong> {this.props.athlete.religion}
                        </p>
                    </Col>
                    <Col xs={12} sm={4}>
                        <h3>Social Media</h3>
                        {this.props.athlete.twitter !== null && (
                            <a href={this.props.athlete.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-fw fa-2x text-blue"></i></a>
                        )}
                        {this.props.athlete.facebook !== null && (
                            <a href={this.props.athlete.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-fw fa-2x text-blue"></i></a>
                        )}
                        {this.props.athlete.snapchat !== null && (
                            <a href={this.props.athlete.snapchat} target="_blank" rel="noopener noreferrer"><i className="fab fa-snapchat fa-fw fa-2x text-blue"></i></a>
                        )}
                        {this.props.athlete.instagram !== null && (
                            <a href={this.props.athlete.instagram} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-fw fa-2x text-blue"></i></a>
                        )}
                        {this.props.athlete.youtube !== null && (
                            <a href={this.props.athlete.youtube} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube fa-fw fa-2x text-blue"></i></a>
                        )}
                    </Col>
                </Row>

                <hr/>

                <Row>
                    <Col xs={12} sm={6} xl={3}>
                        <h3>Highschool Attended</h3>

                        <p>
                            <strong>Name:</strong> {this.props.athlete.highschoolName}<br/>
                            <strong>City:</strong> {this.props.athlete.highschoolCity}<br/>
                            <strong>State:</strong> {this.props.athlete.highschoolState}<br/>
                            <strong>Graduation Year:</strong> {this.props.athlete.graduationYear}
                        </p>

                        <p>
                            <strong>Coach Email:</strong> {this.props.athlete.highschoolCoachEmail}<br/>
                            <strong>Coach Phone:</strong> {this.props.athlete.highschoolCoachPhone}
                        </p>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <h3>Test Scores</h3>

                        {this.props.athlete.transcript !== null && (
                            <p><strong>Transcript:</strong> <Button variant="link" onClick={() => this.openFile(this.props.athlete.transcript)} className="p-0"><i className="fas fa-scroll fa-fw"></i></Button></p>
                        )}

                        <p>
                            <strong>GPA:</strong> {this.props.athlete.gpa}<br/>
                            <strong>SAT:</strong> {this.props.athlete.sat}<br/>
                            <strong>ACT:</strong> {this.props.athlete.act}
                        </p>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <h3>Competed In</h3>

                        <p>
                            {this.props.athlete.shotgunSuper !== null && <span className="d-block">Shotgun: Super Sporting</span>}
                            {this.props.athlete.shotgunClays !== null && <span className="d-block">Shotgun: Sporting Clays</span>}
                            {this.props.athlete.shotgunAmericanSkeet !== null && <span className="d-block">Shotgun American: Skeet</span>}
                            {this.props.athlete.shotgunAmericanTrap !== null && <span className="d-block">Shotgun American: Skeet</span>}
                            {this.props.athlete.shotgunInternationalSkeet !== null && <span className="d-block">Shotgun International: Skeet</span>}
                            {this.props.athlete.shotgunInternationalTrap !== null && <span className="d-block">Shotgun International: Skeet</span>}
                            {this.props.athlete.rifle !== null && <span className="d-block">Rifle</span>}
                            {this.props.athlete.pistol !== null && <span className="d-block">Pistol</span>}
                        </p>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <h3>Member Of</h3>
                        <p>
                            {this.props.athlete.usayess !== null && <span className="d-block">USAYESS</span>}
                            {this.props.athlete.nsca !== null && <span className="d-block">NSCA</span>}
                            {this.props.athlete.nssa !== null && <span className="d-block">NSSA</span>}
                            {this.props.athlete.usa !== null && <span className="d-block">USASHOOTING</span>}
                            {this.props.athlete.sssfSctp !== null && <span className="d-block">SCTP</span>}
                            {this.props.athlete.fourH !== null && <span className="d-block">4-H SHOOTING</span>}
                            {this.props.athlete.ata !== null && <span className="d-block">ATA</span>}
                            {this.props.athlete.nra !== null && <span className="d-block">NRA</span>}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {Object.keys(this.props.athlete.schoolRecords).map((value) => {
                        let schoolRecord = this.props.athlete.schoolRecords[value];
                        let gauges = [];

                        if (schoolRecord.gauge12) {
                            gauges.push(12)
                        }

                        if (schoolRecord.gauge20) {
                            gauges.push(20)
                        }
 
                        if (schoolRecord.gauge28) {
                            gauges.push(28)
                        }

                        if (schoolRecord.gauge410) {
                            gauges.push(410)
                        }

                        return (
                            <Col xs={12} sm={6} xl={3} key={value}>
                                <h3>{value}</h3>
                                
                                <p>
                                    <strong>Total Registered Targets Shot:</strong> {schoolRecord.totalTargets}
                                </p>

                                <p>
                                    <strong>Competed In:</strong><br/>
                                    {schoolRecord.shotgunSuper !== null && (<span className="d-block">Shotgun: Super Sporting</span>)}
                                    {schoolRecord.shotgunClays !== null && (<span className="d-block">Shotgun: Sporting Clays</span>)}
                                    {schoolRecord.shotgunAmericanSkeet !== null && (<span className="d-block">Shotgun American: Skeet</span>)}
                                    {schoolRecord.shotgunAmericanTrap !== null && (<span className="d-block">Shotgun American: Trap</span>)}
                                    {schoolRecord.shotgunInternationalSkeet !== null && (<span className="d-block">Shotgun International: Skeet</span>)}
                                    {schoolRecord.shotgunInternationalTrap !== null && (<span className="d-block">Shotgun International: Trap</span>)}
                                    {schoolRecord.rifle !== null && (<span className="d-block">Rifle</span>)}
                                    {schoolRecord.pistol !== null && (<span className="d-block">Pistol</span>)}
                                </p>

                                {gauges.length > 0 && (
                                    <p>
                                        <strong>Gauges Shot:</strong> {gauges.join(' / ')}
                                    </p>
                                )}

                                <p>
                                    <strong>Local Competitions:</strong><br/>
                                    {schoolRecord.localWins || 0} Wins / {schoolRecord.localPlacements || 0} Placed / {schoolRecord.localHoa || 0} HOA / {schoolRecord.localTotal || 0} Total<br/>

                                    <strong>State Competitions:</strong><br/>
                                    {schoolRecord.stateWins || 0} Wins / {schoolRecord.statePlacements || 0} Placed / {schoolRecord.stateHoa || 0} HOA / {schoolRecord.stateTotal || 0} Total<br/>

                                    <strong>National Competitions:</strong><br/>
                                    {schoolRecord.nationalWins || 0} Wins / {schoolRecord.nationalPlacements || 0} Placed / {schoolRecord.nationalHoa || 0} HOA / {schoolRecord.nationalTotal || 0} Total
                                </p>

                                <p>
                                    <strong>State Ranking:</strong> {schoolRecord.stateRank}<br/>
                                    <strong>National Ranking:</strong> {schoolRecord.nationalRank}
                                </p>

                                <p>
                                    <strong>Special Awards:</strong> {schoolRecord.specialAwards}<br/>
                                    <strong>Community Service Hours:</strong> {schoolRecord.communityService || 0}
                                </p>
                            </Col>
                        );
                    })}
                </Row>

                <hr/>

                <h3>College Goals</h3>
                <Row>
                    <Col xs={12} sm={6}>
                        <strong>Looking to Compete in:</strong><br/>
                        {this.props.athlete.collegeShotgunSuper !== null && (<span className="d-block">Shotgun: Super Sporting</span>)}
                        {this.props.athlete.collegeShotgunClays !== null && (<span className="d-block">Shotgun: Sporting Clays</span>)}
                        {this.props.athlete.collegeShotgunAmericanSkeet !== null && (<span className="d-block">Shotgun American: Skeet</span>)}
                        {this.props.athlete.collegeShotgunAmericanTrap !== null && (<span className="d-block">Shotgun American: Trap</span>)}
                        {this.props.athlete.collegeShotgunInternationalSkeet !== null && (<span className="d-block">Shotgun International: Skeet</span>)}
                        {this.props.athlete.collegeShotgunInternationalTrap !== null && (<span className="d-block">Shotgun International: Trap</span>)}
                        {this.props.athlete.collegeRifle !== null && (<span className="d-block">Rifle</span>)}
                        {this.props.athlete.collegePistol !== null && (<span className="d-block">Pistol</span>)}
                    </Col>
                    <Col xs={12} sm={6}>
                        <strong>Desired Locations:</strong>
                        {Object.keys(this.props.athlete.collegeLocations).map((value) => {
                            return <div key={value}>{CollegeLocations[value]}</div>
                        })}
                    </Col>
                </Row>

                {this.props.athlete.guardians && this.props.athlete.guardians.length > 0 && (
                    <>
                        <hr/>

                        <h3>Guardians</h3>
                        <Row>
                            {this.props.athlete.guardians.map((x, i) => {
                                return (
                                    <Col xs={12} sm={4} key={i}>
                                        <p>
                                            <strong>Name:</strong> {x.name}<br/>
                                            <strong>Relationship:</strong> {x.relationship}
                                        </p>

                                        <p>
                                            <strong>Email:</strong> {x.email}<br/>
                                            <strong>Phone:</strong> {x.phone}<br/>
                                            <strong>Address:</strong> {x.address}
                                        </p>
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                )}

                {this.props.athlete.endorsements && this.props.athlete.endorsements.length > 0 && (
                    <>
                        <hr/>

                        <h3>Endorsements</h3>
                        <Row>
                            {this.props.athlete.endorsements.map((x, i) => {
                                return (
                                    <Col xs={12} sm={4} key={i}>
                                        <p>
                                            <strong>Name:</strong> {x.name}<br/>
                                            <strong>Relationship:</strong> {x.relationship}<br/>
                                            <strong>Professional Career:</strong> {x.career}
                                        </p>

                                        <p>
                                            <strong>Email:</strong> {x.email}<br/>
                                            <strong>Phone:</strong> {x.phone}<br/>
                                        </p>
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                )}

                {this.props.athlete.tournaments && this.props.athlete.tournaments.length > 0 && (
                    <>
                        <hr/>

                        <h3>Upcoming Tournaments</h3>
                        <Row>
                            {this.props.athlete.tournaments.map((x, i) => {
                                let outcome;
                                let gauges = [];

                                switch(x.outcome) {
                                    case 1:
                                        outcome = 'Win';
                                        break;
                                    case 2:
                                        outcome = 'Placement';
                                        break;
                                    case 3:
                                        outcome = 'HOA';
                                        break;
                                    default:
                                        outcome = 'None';
                                        break;
                                }

                                if (x.gauge12) {
                                    gauges.push(12)
                                }

                                if (x.gauge20) {
                                    gauges.push(20)
                                }
         
                                if (x.gauge28) {
                                    gauges.push(28)
                                }

                                if (x.gauge410) {
                                    gauges.push(410)
                                }

                                return (
                                    <Col xs={12} sm={4} key={i}>
                                        <p>
                                            <strong>Event Name:</strong> {x.eventName}<br/>
                                            <strong>Event Date:</strong> {this._tournamentEventDates[i]}<br/>
                                            <strong>Outcome:</strong> {outcome}
                                        </p>

                                        <p>
                                            <strong>Competed In:</strong><br/>
                                            {x.americanSkeet!== null && (<span className="d-block">Shotgun American: Skeet</span>)}
                                            {x.americanTrap !== null && (<span className="d-block">Shotgun American: Trap</span>)}
                                            {x.internationalSkeet !== null && (<span className="d-block">Shotgun International: Skeet</span>)}
                                            {x.internationalTrap !== null && (<span className="d-block">Shotgun International: Trap</span>)}
                                            {x.rifle !== null && (<span className="d-block">Rifle</span>)}
                                            {x.pistol !== null && (<span className="d-block">Pistol</span>)}
                                            {x.superSporting !== null && (<span className="d-block">Shotgun: Super Sporting</span>)}
                                            {x.sportingClay !== null && (<span className="d-block">Shotgun: Sporting Clays</span>)}
                                        </p>

                                        {gauges.length > 0 && (
                                            <p>
                                                <strong>Gauges Shot:</strong> {gauges.join(' / ')}
                                            </p>
                                        )}
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                )}
            </>
        );
    }

    getFilePath = (stateKey, relativeFilePath) => {
        Storage.get(relativeFilePath, {
            level: 'protected',
            identityId: this.props.athlete.identityId
        })
        .then(result => {
            this.setState({ [stateKey]: result });
        })
        .catch(err => console.log(err));
    }

    getFilePaths = (stateKey, filePaths) => {
        let promises = filePaths.map(x => {
            return Storage.get(x, {
                level: 'protected',
                identityId: this.props.athlete.identityId
            })
        });

        Promise.all(promises)
        .then(results => {
            this.setState({ [stateKey]: results });
        })
        .catch(err => console.log(err));
    }

    openFile = (filePath) => {
        Storage.get(filePath, {
            level: 'protected',
            identityId: this.props.athlete.identityId
        })
        .then(result => {
            window.open(result);
        })
        .catch(err => console.log(err));
    }
}

export default ReadOnly;
