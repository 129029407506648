import React from 'react'
import Button from 'react-bootstrap/Button';
import ReadOnly from '../Athlete/ReadOnly';
import Loading from './../Loading';
import { API } from 'aws-amplify';

class Athlete extends React.Component {
    state = {
        athlete: null,
        updating: false
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            API.get('AuthenticatedAPI', `/athletes/${this.props.match.params.id}`).then(athlete => {
                this.setState({ athlete });
            }).catch(error => {
                console.log(error.responses);
            });
        }
    }

    render() {
        if (this.state.athlete === null) {
            return (
                <Loading className="vh-100" />
            );
        }

        return (
            <>
                <div className="bg-white p-1 border-bottom border-blue">
                    <Button variant={this.state.athlete.committed ? 'blue' : 'red'} onClick={this.toggleCommitted} disabled={this.state.updating}>{this.state.athlete.committed ? 'I am Committed' : 'I am not Commited'}</Button>
                </div>

                <div className="p-3">
                    <ReadOnly athlete={this.state.athlete} />
                </div>
            </>
        );
    }

    toggleCommitted = async () => {
        this.setState({ updating: true });

        try {
            await API.put('AuthenticatedAPI', `/athletes/${this.state.athlete.id}`, {
                body: {
                    ...this.state.athlete,
                    committed: !this.state.athlete.committed
                }
            });

            this.setState({ updating: false, athlete: { ...this.state.athlete, committed: !this.state.athlete.committed }});
        } catch (error) {
            this.setState({ updating: false });
            console.log(error);
            console.log(error.responses);
            return;
        }
    }
}

export default Athlete;
