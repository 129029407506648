import React from 'react';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Auth } from 'aws-amplify';

class Navigation extends React.Component {
    state = {
        redirectToLogin: false
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login' }} />
        }

        return (
            <div className="bg-blue">
                <Container fluid>
                    <Navbar variant="dark" expand="sm">
                        <Navbar.Brand as={Link} to="/" href="/"><Image src="/images/logo-xs.png" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="primary-navigation" />

                        <Navbar.Collapse id="primary-navigation">
                            <Nav activeKey={this.props.location.pathname} className="w-100">
                                <Nav.Link as={Link} to="/coach" href="/coach">Athletes</Nav.Link>
                                <Nav.Link as={Link} to="/coach/profile" href="/coach/profile" className="mr-auto">Profile</Nav.Link>
                                <Button variant="red" onClick={this.logout}>Sign Out</Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </div>
        );
    }

    logout = async () => {
        try {
            await Auth.signOut();

            this.setState({ redirectToLogin: true });
        } catch (error) {
            console.log('error signing out:', error);
        }
    }
}

export default withRouter(Navigation);
