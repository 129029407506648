import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth } from 'aws-amplify';

const MAX_FAILED_ATTEMPTS = 3;

class Login extends React.Component {
    state = {
        email: '',
        password: '',
        error: null,
        submitting: false,
        verified: false,
        confirmed: false,
        expired: false,
        failedAttempts: 0,
        redirectToVerification: false,
        redirectToAthlete: false,
        redirectToCoach: false
    }

    constructor(props) {
        super(props);

        if (this.props.location.state) {
            if (this.props.location.state.email) {
                this.state.email = this.props.location.state.email;
            }

            if (this.props.location.state.verified) {
                this.state.verified = this.props.location.state.verified;
            }

            if (this.props.location.state.confirmed) {
                this.state.confirmed = this.props.location.state.confirmed;
            }

            if (this.props.location.state.expired) {
                this.state.expired = this.props.location.state.expired;
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    render() {
        if (this.state.redirectToVerification) {
            return <Redirect to={{ pathname: '/verify', state: { email: this.state.email } }} />
        } else if (this.state.redirectToAdmin) {
            return <Redirect to={{ pathname: '/admin' }} />
        } else if (this.state.redirectToAthlete) {
            return <Redirect to={{ pathname: '/athlete' }} />
        } else if (this.state.redirectToCoach) {
            return <Redirect to={{ pathname: '/coach' }} />
        } else if(this.state.failedAttempts >= MAX_FAILED_ATTEMPTS) {
          return <Redirect to={{ pathname: '/reset-password' }} />
        }

        return (
            <div className="bg-gray py-5">
                <Container>
                    <h1>Sign In</h1>
                    <p className="lead">Welcome back to College Shooting Sports Recruiting!</p>

                    <hr/>

                    <Row className="justify-content-center">
                        <Col sm={10} md={8} lg={6} xl={4} className="bg-white rounded py-3">
                            {this.state.verified && (
                                <Alert variant="light-blue">Your account is verified. Login below to get started!</Alert>
                            )}

                            {this.state.confirmed && (
                                <Alert variant="light-blue">Your password has been reset. Login below to get started!</Alert>
                            )}

                            <h3>Enter Your Credentials</h3>

                            <Form onSubmit={this.handleFormSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="email@address.com" required onChange={this.handleInputChange} value={this.state.email} />
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$" onChange={this.handleInputChange} value={this.state.password} />
                                    <Form.Text className="text-muted">Must be at least 8 character long, contain one uppercase letter, one lowercase letter, and one number.</Form.Text>
                                </Form.Group>

                                {this.state.error && (
                                   <Alert variant="red">{this.state.error}</Alert>
                                )}

                                <Button size={"lg"} variant="blue" type="submit" block disabled={this.state.submitting}>Sign In</Button>
                            </Form>
                        </Col>
                    </Row>

                    <hr/>

                    <p>If you do not have an account, please <Link to="/register" href="/register">create an account</Link> first.</p>
                    <p>If you need help remembering your password, please <Link to="/reset-password" href="/reset-password">request a password reset</Link>.</p>
                </Container>
            </div>
        );
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({ [name] : value });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ submitting: true });

        try {
            const user = await Auth.signIn({
                username: this.state.email,
                password: this.state.password,
            });

            if (!user.signInUserSession) {
                switch (user.challengeName) {
                    case 'NEW_PASSWORD_REQUIRED':
                        await Auth.completeNewPassword(user, this.state.password);
                        break;
                    default:
                        console.log('error with login challenge:', user.challengeName);
                        break;
                }
            }

            let groups = user.signInUserSession.idToken.payload['cognito:groups'];
            let group = null;

            if (groups && groups.length > 0) {
                group = groups[0];
            }

            switch (group) {
                case 'admin':
                    this.setState({ redirectToAdmin: true });
                    break;
                case 'athlete':
                    this.setState({ redirectToAthlete: true });
                    break;
                case 'coach':
                    this.setState({ redirectToCoach: true });
                    break;
                default:
                    this.setState({ submitting: false });
                    throw new Error('Unrecognized user group.');
            }
        } catch (error) {
            switch (error.code) {
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                    this.setState({ failedAttempts: this.state.failedAttempts + 1 });
                    this.setState({ error: error.message, submitting: false });
                    break;
                case 'UserNotConfirmedException':
                    this.setState({ redirectToVerification: true });
                    break;
                default:
                    console.log('error signing in:', error);
                    break;
            }
        }
    }
}

export default Login;
