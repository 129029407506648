import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import awsImports from './aws-imports';
import * as serviceWorker from './serviceWorker';

Amplify.configure(awsExports);
Amplify.configure(awsImports);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
