import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from '../Loading';
import Navigation from './Navigation';
import Profile from './Profile';
import Settings from './Settings';
import { Auth, API } from 'aws-amplify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublishableKey } from './../config';
import Subscribe from './Subscribe';

class App extends React.Component {
    state = {
        athlete: null,
        redirectToLogin: false
    }

    _stripePromise;

    constructor(props) {
        super(props);

        this._stripePromise = loadStripe(stripePublishableKey);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(user => {
            if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'athlete') {
                this.setState({ redirectToLogin: true });
            } else {
                API.get('AuthenticatedAPI', `/athletes/${user.username}`).then(athlete => {
                    Auth.currentCredentials().then(creds => {
                        this.setState({ athlete: { ...athlete, identityId: creds.identityId } });
                    });
                }).catch(error => {
                    console.log(error.responses);
                });
            }
        }).catch(error => {
            this.setState({ redirectToLogin: true });
        });
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to={{ pathname: '/login' }} />
        }

        if (!this.state.athlete) {
            return (
                <Loading className="vh-100 bg-gray" />
            );
        }

        if (!this.state.athlete.subscriptionId) {
            return (
                <Elements stripe={this._stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto' }] }}>
                    <Subscribe athleteId={this.state.athlete.id} />
                </Elements>
            );
        }

        return (
            <div className="min-vh-100 bg-gray">
                <Navigation />

                <Switch>
                    <Route exact path="/athlete">
                        <Profile athlete={this.state.athlete} />
                    </Route>
                    <Route exact path="/athlete/settings">
                        <Elements stripe={this._stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto' }] }}>
                            <Settings subscriptionId={this.state.athlete.subscriptionId} />
                        </Elements>
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default App;
