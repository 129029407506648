import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

class Footer extends React.Component {
    render() {
        let currentDate = new Date();

        return (
            <div className="bg-blue text-white py-4 small">
                <Container>

                    <Row>
                        <Col sm={8} md={4} lg={5}>
                            <h5>College Shooting Sports Recruiting</h5>
                            <p>
                                401 North Carroll Avenue, Suite 127<br/>
                                Southlake, Texas 76092, United States
                            </p>
                            <p><a href="tel:18552973785" className="text-light-blue font-weight-bold"><i className="fas fa-phone-square fa-fw fa-lg"></i> 855-297-3785</a></p>
                        </Col>
                        <Col xs={{ order: 1 }} sm={{ span: 4, order: 0 }} md={3}>
{/*
                            <h5>Sponsored By</h5>
                            <Image fluid src="/images/usayess.png" />
*/}
                        </Col>
                        <Col sm={8} md={5} lg={4} className="text-left text-md-right">
                            <ul className="list-unstyled font-weight-bold">
                                <li><Link to="/terms-and-conditions" className="text-light-blue">Terms &amp; Conditions</Link></li>
                                <li><Link to="/privacy-policy" className="text-light-blue">Privacy Policy</Link></li>
                                <li><Link to="/cancellation-policy" className="text-light-blue">Cancellation Policy</Link></li>
                            </ul>

                            <a href="https://www.facebook.com/CollegeShootingSportsRecruiting" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square fa-fw fa-3x text-gray"></i></a>
                            <a href="https://www.instagram.com/collegeshootingsports/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram-square fa-fw fa-3x text-gray"></i></a>
                            <a href="https://twitter.com/CollegeShooting" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square fa-fw fa-3x text-gray"></i></a>
                            <a href="https://www.linkedin.com/company/level-up-athletics-llc" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-fw fa-3x text-gray"></i></a>

                            <hr className="border-white d-sm-none" />
                        </Col>
                    </Row>

                    <hr className="border-white" />

                    <p className="mb-0">Copyright &copy; { currentDate.getFullYear() } College Shooting Sports Recruiting, a part of Level UP Athletics, LLC. - All Rights Reserved</p>
                </Container>
            </div>
        );
    }
}

export default Footer;
