import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

class SchoolRecord extends React.Component {
    state = {
        disabled: true
    }

    constructor(props) {
        super(props);

        this.state.disabled = Object.keys(props.schoolRecord).length === 0;
    }

    render() {

        return (
            <>
                <Form.Group>
                    <Form.Check type="checkbox" id={this.props.class} label={this.props.class} onChange={this._handleClassCheck} className="font-weight-bold" defaultChecked={!this.state.disabled} />
                </Form.Group>

                {!this.state.disabled && (
                    <>
                        <Form.Group controlId="totalTargets">
                            <Form.Label><i className="fas fa-bullseye fa-fw"></i> Total Registered Targets Shot</Form.Label>
                            <Form.Control type="number" name={`schoolRecords[${this.props.class}][totalTargets]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord.totalTargets} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label><i className="fas fa-trophy fa-fw"></i> Competed In</Form.Label>
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunSuper]`} name={`schoolRecords[${this.props.class}][shotgunSuper]`} label="Shotgun: Super Sporting" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunSuper} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunClays]`} name={`schoolRecords[${this.props.class}][shotgunClays]`} label="Shotgun: Sporting Clays" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunClays} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunAmericanSkeet]`} name={`schoolRecords[${this.props.class}][shotgunAmericanSkeet]`} label="Shotgun American: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunAmericanSkeet} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunAmericanTrap]`} name={`schoolRecords[${this.props.class}][shotgunAmericanTrap]`} label="Shotgun American: Trap" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunAmericanTrap} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunInternationalSkeet]`} name={`schoolRecords[${this.props.class}][shotgunInternationalSkeet]`} label="Shotgun International: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunInternationalSkeet} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][shotgunInternationalTrap]`} name={`schoolRecords[${this.props.class}][shotgunInternationalTrap]`} label="Shotgun International: Trap" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.shotgunInternationalTrap} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][rifle]`} name={`schoolRecords[${this.props.class}][rifle]`} label="Rifle" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.rifle} />
                            <Form.Check type="checkbox" id={`schoolRecords[${this.props.class}][pistol]`} name={`schoolRecords[${this.props.class}][pistol]`} label="Pistol" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.pistol} />
                        </Form.Group>

                        <Form.Label><i className="fas fa-ruler fa-fw"></i> Gauges Shot</Form.Label>
                        <Form.Group>
                            <Form.Check type="checkbox" inline id={`schoolRecords[${this.props.class}][gauge12]`} name={`schoolRecords[${this.props.class}][gauge12]`} label="12" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.gauge12} />
                            <Form.Check type="checkbox" inline id={`schoolRecords[${this.props.class}][gauge20]`} name={`schoolRecords[${this.props.class}][gauge20]`} label="20" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.gauge20} />
                            <Form.Check type="checkbox" inline id={`schoolRecords[${this.props.class}][gauge28]`} name={`schoolRecords[${this.props.class}][gauge28]`} label="28" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.gauge28} />
                            <Form.Check type="checkbox" inline id={`schoolRecords[${this.props.class}][gauge410]`} name={`schoolRecords[${this.props.class}][gauge410]`} label="410" onChange={this.props.inputChange} defaultChecked={this.props.schoolRecord.gauge410} />
                        </Form.Group>

                        {['Local', 'State', 'National'].map((value) => {
                            let lowerValue = value.toLowerCase();

                            return (
                                <div key={value}>
                                    <Form.Label><i className="fas fa-medal fa-fw"></i> # of {value} Competitions</Form.Label>

                                    <Row noGutters>
                                        <Col xs={3}>
                                            <Form.Group controlId={`${lowerValue}Total`}>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" name={`schoolRecords[${this.props.class}][${lowerValue}Total]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord[`${lowerValue}Total`]} />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={3}>
                                            <Form.Group controlId={`${lowerValue}Wins`}>
                                                <Form.Label>Wins</Form.Label>
                                                <Form.Control type="number" name={`schoolRecords[${this.props.class}][${lowerValue}Wins]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord[`${lowerValue}Wins`]} />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={3}>
                                            <Form.Group controlId={`${lowerValue}Placements`}>
                                                <Form.Label>Placed</Form.Label>
                                                <Form.Control type="number" name={`schoolRecords[${this.props.class}][${lowerValue}Placements]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord[`${lowerValue}Placements`]} />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={3}>
                                            <Form.Group controlId={`${lowerValue}Hoa`}>
                                                <Form.Label>HOA</Form.Label>
                                                <Form.Control type="number" name={`schoolRecords[${this.props.class}][${lowerValue}Hoa]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord[`${lowerValue}Hoa`]} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}

                        <Form.Label><i className="fas fa-crown fa-fw"></i> Rankings</Form.Label>
                        <Row>
                            {['State', 'National'].map((value) => {
                                let lowerValue = value.toLowerCase();

                                return (
                                    <Col xs={6} key={value}>
                                        <Form.Group controlId={`${lowerValue}Rank`}>
                                            <Form.Label>{value}</Form.Label>
                                            <Form.Control type="number" name={`schoolRecords[${this.props.class}][${lowerValue}Rank]`} size={"sm"} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord[`${lowerValue}Rank`]} />
                                        </Form.Group>
                                    </Col>
                                );
                            })}
                        </Row>

                        <Form.Group controlId="specialAwards">
                            <Form.Label><i className="fas fa-award fa-fw"></i> Special Awards</Form.Label>
                            <Form.Control type="text" size={"sm"} name={`schoolRecords[${this.props.class}][specialAwards]`} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord.specialAwards} />
                        </Form.Group>

                        <Form.Group controlId="communityService">
                            <Form.Label><i className="fas fa-hands-helping fa-fw"></i> Community Service Hours</Form.Label>
                            <Form.Control type="number" size={"sm"} name={`schoolRecords[${this.props.class}][communityService]`} onChange={this.props.inputChange} defaultValue={this.props.schoolRecord.communityService} />
                        </Form.Group>
                    </>
                )}
            </>
        );
    }

    _handleClassCheck = (event) => {
        this.setState({ disabled: !event.target.checked });
    }
}

export default SchoolRecord;
