import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CollegeLocations from './../CollegeLocations';

class College extends React.Component {
    render() {
        let locations = this.props.athlete.collegeLocations || [];
        let locationKeys = Object.keys(locations);

        return (
            <Row>
                <Col xs={12} sm={6}>
                    <fieldset>
                        <legend>Looking to Compete In</legend>

                        <Form.Group>
                            <Form.Check type="checkbox" id="collegeShotgunSuper" name="collegeShotgunSuper" label="Shotgun: Super Sporting" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunSuper} />
                            <Form.Check type="checkbox" id="collegeShotgunClays" name="collegeShotgunClays" label="Shotgun: Sporting Clays" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunClays} />
                            <Form.Check type="checkbox" id="collegeShotgunAmericanSkeet" name="collegeShotgunAmericanSkeet" label="Shotgun American: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunAmericanSkeet} />
                            <Form.Check type="checkbox" id="collegeShotgunAmericanTrap" name="collegeShotgunAmericanTrap" label="Shotgun American: Trap" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunAmericanTrap} />
                            <Form.Check type="checkbox" id="collegeShotgunInternationalSkeet" name="collegeShotgunInternationalSkeet" label="Shotgun International: Skeet" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunInternationalSkeet} />
                            <Form.Check type="checkbox" id="collegeShotgunInternationalTrap" name="collegeShotgunInternationalTrap" label="Shotgun International: Trap" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeShotgunInternationalTrap} />
                            <Form.Check type="checkbox" id="collegeRifle" name="collegeRifle" label="Rifle" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegeRifle} />
                            <Form.Check type="checkbox" id="collegePistol" name="collegePistol" label="Pistol" onChange={this.props.inputChange} defaultChecked={this.props.athlete.collegePistol} />
                        </Form.Group>
                    </fieldset>
                </Col>

                <Col xs={12} sm={6}>
                    <fieldset>
                        <legend>Desired Locations</legend>

                        <Form.Group>
                            {Object.keys(CollegeLocations).map(x => {
                                return <Form.Check key={x} type="checkbox" id={x} name={`collegeLocations[${x}]`} label={CollegeLocations[x]} onChange={this.props.inputChange} defaultChecked={locationKeys.includes(x)} />
                            })}
                        </Form.Group>
                    </fieldset>
                </Col>
            </Row>
        );
    }
}

export default College;
